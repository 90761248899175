<div class="row" *ngIf="value">
  <section class="columns">
    <app-data-selector
      label="Tipo de Vacina"
      [repository]="vaccineTypesRepository"
      [value]="value.type"
      (valueChange)="onTypeChange($event)"
    ></app-data-selector>
    <app-data-selector
      label="Produto"
      [filters]="operatableFilters"
      [repository]="operatablesRepository"
      [value]="value.operatable"
      [disabled]="!value.type"
      (valueChange)="onOperatableChange($event)"
    ></app-data-selector>
    <mat-form-field>
      <mat-label>Dose</mat-label>
      <mat-select
        [value]="value.dose"
        (valueChange)="onDoseChange($event)"
        [disabled]="!value.type"
      >
        <mat-option *ngFor="let dose of doses" [value]="dose">
          {{ dose.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-data-selector
      label="Médico"
      [repository]="doctorsRepository"
      [value]="value.doctor"
      [disabled]="!value.type"
      (valueChange)="onDoctorChange($event)"
    ></app-data-selector>
  </section>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mail-input-dialog',
  templateUrl: './mail-input-dialog.component.html',
  styleUrls: ['./mail-input-dialog.component.scss']
})
export class MailInputDialogComponent implements OnInit {
  mail = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<MailInputDialogComponent>
  ) {
    if (data && data.mail) {
      this.mail.setValue(data.mail);
    }
  }

  ngOnInit() { }

  submit() {
    if (this.mail.valid) {
      this.dialogRef.close(this.mail.value);
    }
  }
}

import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { VaccinationReceptionDataComponent } from './vaccination-reception-data/vaccination-reception-data.component';
import { VaccinationReceptionPaymentComponent } from './vaccination-reception-payment/vaccination-reception-payment.component';
import { VaccinationReceptionPaymentGuard } from './vaccination-reception-payment/vaccination-reception-payment.guard';
import { VaccinationReceptionPostFinishComponent } from './vaccination-reception-post-finish/vaccination-reception-post-finish.component';
import { VaccinationReceptionPostFinishGuard } from './vaccination-reception-post-finish/vaccination-reception-post-finish.guard';
import { VaccinationReceptionComponent } from './vaccination-reception.component';
import { AuthGuard } from '@eceos/arch';

export const routes: Routes = [
  {
    path: 'reception/forPatient/:pid',
    component: VaccinationReceptionComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'data',
        pathMatch: 'full'
      },
      {
        path: 'data',
        component: VaccinationReceptionDataComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'payment',
        component: VaccinationReceptionPaymentComponent,
        canActivate: [AuthGuard, VaccinationReceptionPaymentGuard]
      },
      {
        path: 'postFinish',
        component: VaccinationReceptionPostFinishComponent,
        canActivate: [AuthGuard, VaccinationReceptionPostFinishGuard]
      }
    ]
  }
];

export const VaccinationReceptionRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });

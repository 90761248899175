import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { Hotkey, HotkeySet } from '@eceos/common-utils';
import {
  FiscalDocumentSummary,
  VaccineApplication,
  VaccineApplicationsRepository
} from '@eceos/domain';
import { LayoutBackComponent } from '../../../shared/layout/layout-back/layout-back.component';
import { MailInputDialogComponent } from '../../../shared/mail-input-dialog/mail-input-dialog.component';
import { SettingsRepository } from '../../settings/settings.repository';
import { VaccinationReceptionService } from '../vaccination-reception.service';

@Component({
  selector: 'app-vaccination-reception-post-finish',
  templateUrl: './vaccination-reception-post-finish.component.html',
  styleUrls: ['./vaccination-reception-post-finish.component.scss']
})
export class VaccinationReceptionPostFinishComponent implements OnInit, OnDestroy {
  hotkeys = HotkeySet.of([
    Hotkey.key('f3')
      .description('Transmitir/Imprimir nota fiscal')
      .do(() => (this.hasFiscalDocument ? this.printFiscalDocument() : this.emitFiscalDocument())),
    Hotkey.key('f4')
      .description('Enviar nota fiscal por e-mail')
      .do(() => this.emailFiscalDocument()),
    Hotkey.key('f8')
      .description('Cadastrar novo paciente')
      .do(() => this.openNewPatient())
  ]);

  @ViewChild(LayoutBackComponent) layoutBack: LayoutBackComponent;

  public fiscalDocument: FiscalDocumentSummary;

  loading = false;

  private defaultRequestMetadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private service: VaccinationReceptionService,
    private repository: VaccineApplicationsRepository,
    public dialog: MatDialog,
    private settingsRepository: SettingsRepository
  ) {}

  ngOnInit() {
    this.hotkeys.enable();
  }

  ngOnDestroy() {
    this.hotkeys.disable();
  }

  openNewPatient() {
    this.router.navigate(['/patients', 'new']);
  }

  emitFiscalDocument() {
    if (!this.loading && !this.hasFiscalDocument) {
      this.loading = true;
      this.createAndTransmit();
    }
  }

  printFiscalDocument() {
    if (!this.loading && this.hasFiscalDocument && !this.fiscalDocument.isNfse) {
      if (this.hasDefaultPrinter) {
        this.loading = true;
        this.repository
          .printFiscalDocumentTo(
            this.fiscalDocument,
            this.settingsRepository.defaultReceiptPrinter,
            this.defaultRequestMetadata
          )
          .subscribe(
            r => {
              this.showMessage('Impressão enviada com sucesso');
              this.loading = false;
            },
            e => {
              this.showError(e, 'Erro ao enviar impressão');
              this.loading = false;
            }
          );
      } else {
        this.showMessage('Impressora padrão não encontrada');
      }
    }
  }

  emailFiscalDocument() {
    if (!this.loading && this.hasFiscalDocument && !this.fiscalDocument.isNfse) {
      const dialogRef = this.dialog.open(MailInputDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loading = true;
          this.repository
            .mailFiscalDocumentTo(this.fiscalDocument, result, this.defaultRequestMetadata)
            .subscribe(
              r => {
                this.showMessage(`Nota Fiscal enviada para ${result}`);
                this.loading = false;
              },
              e => {
                this.showError(e, 'Erro ao enviar nota fiscal');
                this.loading = true;
              }
            );
        }
      });
    }
  }

  private transmitFiscalDocument() {
    this.repository
      .transmitFiscalDocument(this.fiscalDocument, this.defaultRequestMetadata)
      .subscribe(
        fd => {
          this.fiscalDocument = fd;
          if (this.fiscalDocument.authorized) {
            this.showMessage('Documento fiscal transmitido com sucesso!');
          } else if (this.fiscalDocument.processing) {
            this.showMessage(
              'O documento fiscal foi transmitido e está sendo processado, acesse https://eceos.cloud para maiores detalhes.'
            );
          } else {
            this.showMessage(
              'Não foi possível transmitir a nota pelo PDR, acesse https://eceos.cloud para transmitir.'
            );
          }
          this.loading = false;
        },
        e => {
          this.showError(
            e,
            'Não foi possível transmitir essa nota pelo PDR, acesse https://eceos.cloud para transmitir.'
          );
          this.loading = false;
        }
      );
  }

  private createAndTransmit() {
    this.repository.createFiscalDocument(this.application, this.defaultRequestMetadata).subscribe(
      nfe => {
        this.fiscalDocument = nfe;
        this.transmitFiscalDocument();
      },
      e => {
        this.showError(
          e,
          'Não foi possível gerar a nota pelo PDR, acesse https://eceos.cloud para gerar.'
        );
        this.loading = false;
      }
    );
  }

  get application(): VaccineApplication {
    return this.service.vaccineApplication;
  }

  get hasFiscalDocumentNfeOrNfce(): boolean {
    return this.hasFiscalDocument && (this.fiscalDocument.isNfe || this.fiscalDocument.isNfce);
  }

  get hasFiscalDocument(): boolean {
    return Boolean(this.fiscalDocument);
  }

  get hasDefaultPrinter(): boolean {
    return Boolean(this.settingsRepository.defaultReceiptPrinter);
  }

  private showError(e: Error, message: string) {
    if (e) {
      console.log(e);
    }
    this.showMessage(message);
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }
}

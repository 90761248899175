import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '@eceos/arch';
import { Contact } from '@eceos/domain';

enum ContactType {
  CELULAR = 'CELULAR',
  TELEFONE = 'TELEFONE',
  EMAIL = 'EMAIL',
  EMAIL_NFE = 'EMAIL_NFE'
}

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactsComponent),
      multi: true
    }
  ]
})
export class ContactsComponent extends ValueAccessorBase<Contact>
  implements OnInit, ControlValueAccessor {
  constructor() {
    super();
  }

  ngOnInit() {}

  onTypeChange(value: ContactType) {
    this.value.type = value;
    this.publishChange();
  }

  onDetailChange(value: string, invalid: boolean) {
    this.value.detail = value;
    this.invalid = invalid;
    this.publishChange();
  }

  onCommentChange(value: string) {
    this.value.comment = value;
    this.publishChange();
  }
}

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase, Filter, FilterOp } from '@eceos/arch';
import {
  VaccineApplicationItem,
  DoseSummary,
  VaccineTypesRepository,
  OperatablesRepository,
  DosesRepository,
  DoctorsRepository,
  VaccineTypeSummary,
  Operatable,
  Doctor,
  VaccineApplication
} from '@eceos/domain';

@Component({
  selector: 'app-vaccination-reception-data-item',
  templateUrl: './vaccination-reception-data-item.component.html',
  styleUrls: ['./vaccination-reception-data-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VaccinationReceptionDataItemComponent),
      multi: true
    },
  ],
})
export class VaccinationReceptionDataItemComponent
  extends ValueAccessorBase<VaccineApplicationItem>
  implements OnInit {
  operatableFilters: Filter[] = [];
  doses: DoseSummary[] = [];

  @Input() vaccineApplication: VaccineApplication;

  constructor(
    public vaccineTypesRepository: VaccineTypesRepository,
    public operatablesRepository: OperatablesRepository,
    public dosesRepository: DosesRepository,
    public doctorsRepository: DoctorsRepository
  ) {
    super();
  }

  ngOnInit() {}

  onTypeChange(value: VaccineTypeSummary) {
    this.value.type = value;
    this.updateOperatableFilters();
    this.updateDoses();
    this.publishChange();
  }

  onOperatableChange(value: Operatable) {
    this.value.operatable = value;
    if (value) {
      this.value.unitaryPrice = value.defaultSellValue;
    }
    this.publishChange();
  }

  onDoseChange(value: DoseSummary) {
    this.value.dose = value;
    this.publishChange();
  }

  onDoctorChange(value: Doctor) {
    this.value.doctor = value;
    this.publishChange();
  }

  updateOperatableFilters(): void {
    if (this.value.type && this.value.type.productCategory) {
      this.operatableFilters = [
        new Filter('category', FilterOp.EQ, this.value.type.productCategory.id),
        new Filter('withStock', FilterOp.EQ, true),
      ];

      if (this.vaccineApplication?.hasPaymentCovenant()) {
        this.operatableFilters.push(
          new Filter('covenant', null, this.vaccineApplication.paymentCovenant.id)
        );
      }

      if (
        this.vaccineApplication?.responsibleByPatient &&
        this.vaccineApplication?.responsibleByPatient?.client
      ) {
        this.operatableFilters.push(
          new Filter('client', null, this.vaccineApplication.responsibleByPatient.client)
        );
      }
    } else {
      this.operatableFilters = [];
      this.value.operatable = null;
    }
  }

  updateDoses(): void {
    if (this.value.type) {
      this.vaccineTypesRepository.getDoses(this.value.type).subscribe(
        (data) => {
          this.doses = data;
        },
        (e) => {
          this.doses = [];
        },
        () => {
          this.setDose();
        }
      );
    }
  }

  private setDose(): void {
    if (this.value.dose) {
      this.value.dose = this.doses.find((d) => d.id === this.value.dose.id);
    }
  }
}

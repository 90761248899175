import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  ExternalVaccineApplication,
  Patient,
  PatientsRepository,
  VaccinationHistory
} from '@eceos/domain';

@Component({
  selector: 'app-vaccination-histories',
  templateUrl: './vaccination-histories.component.html',
  styleUrls: ['./vaccination-histories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VaccinationHistoriesComponent implements OnInit {
  historyColumns = ['data', 'type', 'product', 'dose'];
  history: VaccinationHistory[] = [];
  externalColumns = ['data', 'local', 'product', 'dose', 'vaccineLot'];
  external: ExternalVaccineApplication[] = [];
  private _patient: Patient;

  constructor(private repository: PatientsRepository, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  get patient() {
    return this._patient;
  }

  @Input()
  set patient(patient: Patient) {
    this._patient = patient;
    if (patient) {
      this.external = patient.externalVaccineApplication;
      this.repository.listVaccinationHistoriesFor(patient).subscribe(v => {
        this.history = v;
        this.changeDetector.detectChanges();
      });
    }
  }
}

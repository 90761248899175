import * as Mousetrap from 'mousetrap';
import {MousetrapInstance} from 'mousetrap';

export class HotkeySet {
  private index: Map<string, Hotkey[]> = new Map();
  private activesHotkeys: Hotkey[] = [];
  private _enabled = false;
  constructor(public hotkeys: Hotkey[], readonly mousetrap: MousetrapInstance) {
    this.reindex();
  }

  get enabled() {
    return this._enabled;
  }

  get allEnabled() {
    return this.hotkeys.every(h => h.enabled);
  }

  get anyEnabled() {
    return this.hotkeys.some(h => h.enabled);
  }

  get allDisabled() {
    return this.hotkeys.every(h => !h.enabled);
  }

  get anyDisabled() {
    return this.hotkeys.some(h => !h.enabled);
  }

  addSet(hks: HotkeySet) {
    for (const hk of hks.hotkeys) {
      this.add(hk);
    }
  }

  removeSet(hks: HotkeySet) {
    for (const hk of hks.hotkeys) {
      this.remove(hk);
    }
  }

  add(hk: Hotkey | HotkeyBuilder): HotkeySet {
    if (hk instanceof Hotkey) {
      this.hotkeys.push(hk);
      this.reindex();
    } else {
      this.add(hk.build(this.mousetrap));
    }
    return this;
  }

  remove(hk: Hotkey): HotkeySet {
    const idx = this.hotkeys.indexOf(hk);
    if (idx > -1) {
      this.hotkeys.splice(idx);
      hk.enabled = false;
      this.reindex();
    }
    return this;
  }

  private reindex() {
    this.index.clear();
    for (const h of this.hotkeys.slice(0).reverse()) {
      for (const k of h.keys) {
        const array = this.index.get(k) || [];
        array.push(h);
        this.index.set(k, array);
      }
    }
    this.activesHotkeys = Array.from(this.index.values()).map(a => a[0]);
    this.disable();
    if (this.enable) {
      this.enable();
    }
  }

  enable() {
    this._enabled = true;
    for (const h of this.activesHotkeys) {
      h.enabled = true;
    }
  }

  disable() {
    this._enabled = false;
    for (const h of this.hotkeys) {
      h.enabled = false;
    }
  }

  static of(hotkeys: HotkeyBuilder[], mousetrap: MousetrapInstance = new Mousetrap()): HotkeySet {
    Hotkey.globalEnableForInputs(mousetrap);
    return new HotkeySet(hotkeys.map(h => h.build(mousetrap)), mousetrap);
  }
}

export class Hotkey {
  private _enabled = false;

  private preventIn = PREVENT_IN.filter(a => this.allowIn.indexOf(a) === -1);

  constructor(
    readonly keys: string[] = [],
    readonly actions: HkAction[] = [],
    readonly allowIn: AllowIn[] = [AllowIn.INPUT, AllowIn.TEXTAREA, AllowIn.SELECT],
    readonly desc = '',
    readonly returnValue = false,
    readonly mousetrap: MousetrapInstance
  ) {}

  get enabled() {
    return this._enabled;
  }

  set enabled(value: boolean) {
    if (this._enabled !== value) {
      if (value) {
        this.bindKey();
      } else {
        this.unbindKey();
      }
    }
    this._enabled = value;
  }

  private bindKey() {
    this.mousetrap.bind(this.keys, (event, combo) => {
      for (const action of this.actions) {
        if (event) {
          // tslint:disable-next-line: deprecation
          const target: HTMLElement = <HTMLElement>(event.target || event.srcElement); // srcElement is IE only
          const nodeName: string = target.nodeName.toUpperCase();
          if (
            (' ' + target.className + ' ').indexOf(' mousetrap ') === -1 &&
            this.preventIn.some(a => a.toString() === nodeName)
          ) {
            continue;
          }
        }
        action.run(event, combo);
      }
      return this.returnValue;
    });
  }

  private unbindKey() {
    this.mousetrap.unbind(this.keys);
  }

  static key(key: string): HotkeyBuilder {
    return new HotkeyBuilder(key);
  }

  static clearAll(mousetrap: MousetrapInstance) {
    mousetrap.reset();
  }

  static globalEnableForInputs(mousetrap: MousetrapInstance) {
    mousetrap.stopCallback = (event: KeyboardEvent, element: HTMLElement, combo: string) => {
      if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
        return false;
      }
      return element.contentEditable && element.contentEditable === 'true';
    };
  }
}

export class HotkeyBuilder {
  private keys: string[] = [];
  private actions: HkAction[] = [];
  private allowIn: AllowIn[] = [AllowIn.INPUT, AllowIn.TEXTAREA, AllowIn.SELECT];
  private desc = '';
  private returnValue = false;

  constructor(key: string) {
    this.keys.push(key);
  }

  key(key: string): HotkeyBuilder {
    this.keys.push(key);
    return this;
  }

  description(desc: string): HotkeyBuilder {
    this.desc = desc;
    return this;
  }

  do(action: () => void): HotkeyBuilder {
    return this.doWith((e: KeyboardEvent, c: string) => action());
  }

  doWith(action: (e: KeyboardEvent, c: string) => void): HotkeyBuilder {
    this.actions.push(new HkAction(action));
    return this;
  }

  asConsumed(): HotkeyBuilder {
    this.returnValue = true;
    return this;
  }

  asUnconsumed(): HotkeyBuilder {
    this.returnValue = false;
    return this;
  }

  build(mousetrap: MousetrapInstance): Hotkey {
    if (this.keys.length === 0) {
      throw Error('Nenhuma key definida para o atalho');
    }
    return new Hotkey(
      this.keys,
      this.actions,
      this.allowIn,
      this.desc,
      this.returnValue,
      mousetrap
    );
  }
}

enum AllowIn {
  INPUT = 'INPUT',
  TEXTAREA = 'TEXTAREA',
  SELECT = 'SELECT'
}

const PREVENT_IN = [AllowIn.INPUT, AllowIn.TEXTAREA, AllowIn.SELECT];

class HkAction {
  constructor(private action: (e: KeyboardEvent, c: string) => void) {}

  run(e: KeyboardEvent, c: string) {
    setTimeout(() => this.action(e, c), 50);
  }
}

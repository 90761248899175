<div class="bg">
  <mat-toolbar id="actionbar" color="primary" class="toolbar">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <mat-icon
      routerLink="/"
      class="mat-icon-logo cursor-pointer"
      svgIcon="assets:logo_short_eceos_white"
    ></mat-icon>
    <span class="center"></span>
    <span>
      <ng-content select=".actions"></ng-content>
    </span>
  </mat-toolbar>
  <div class="content" [class.loading]="loading">
    <ng-content></ng-content>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  logout() {
    localStorage.clear();
    document.location.reload(true);
  }
}

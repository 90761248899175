import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { DoseSummary } from '../doses';
import { VaccineTypeSummary } from '../vaccine-types';

export class ExternalVaccineApplication implements DomainEntity {
  public readonly id = SgvId.gen();
  public vaccineType: VaccineTypeSummary = null;
  public dose: DoseSummary = null;
  public local = '';
  public date: Date = null;
  public vaccineLot: string = null;

  toJson(): any {
    return SgvJson.to.simple(this, {
      vaccineType: this.vaccineType.toJson(),
      dose: this.dose.toJson()
    });
  }

  static fromJson(json: any): ExternalVaccineApplication {
    return SgvJson.from.simple(json, ExternalVaccineApplication, {
      vaccineType: VaccineTypeSummary.fromJson(json.vaccineType),
      dose: DoseSummary.fromJson(json.dose)
    });
  }
}

import { PageNotFoundComponent } from '@eceos/common-pages';
import { LoginComponent } from './login/login.component';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot([
    {
        path: '',
        redirectTo: 'patients',
        pathMatch: 'full'
    },
    { path: 'login', component: LoginComponent },
    { path: '**', component: PageNotFoundComponent }
], { relativeLinkResolution: 'legacy' });

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Hotkey, HotkeySet } from '@eceos/common-utils';
import {
  CashPaymentForm,
  DeferredPaymentForm,
  PaymentCovenantsRepository,
  PermissionService,
  VaccineApplication,
  VaccineApplicationsActions,
  VaccineApplicationsRepository,
  VACCINE_APPLICATIONS
} from '@eceos/domain';
import { VaccinationReceptionService } from '../vaccination-reception.service';

@Component({
  selector: 'app-vaccination-reception-payment',
  templateUrl: './vaccination-reception-payment.component.html',
  styleUrls: ['./vaccination-reception-payment.component.scss']
})
export class VaccinationReceptionPaymentComponent implements OnInit, OnDestroy {
  hotkeys = HotkeySet.of([
    Hotkey.key('f3')
      .description('Mudar para pagamento à vista')
      .do(() => this.application.asCashPaymentForm()),
    Hotkey.key('f4')
      .description('Mudar para pagamento à prazo')
      .do(() => this.application.asDeferredPaymentForm()),
    Hotkey.key('f8')
      .key('ctrl+enter')
      .description('Concluir pagamento')
      .do(() => this.submitAndNext())
  ]);
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private repository: VaccineApplicationsRepository,
    public snackbar: MatSnackBar,
    private service: VaccinationReceptionService,
    private permissions: PermissionService,
    private paymentCovenantsRepository: PaymentCovenantsRepository
  ) {
    if (this.application.hasPaymentCovenant()) {
      if (this.application.paymentCovenant.charged) {
        this.application.withoutPayment();
      }
    }
  }

  ngOnInit() {
    this.hotkeys.enable();
  }
  ngOnDestroy() {
    this.hotkeys.disable();
  }

  get application(): VaccineApplication {
    return this.service.vaccineApplication;
  }

  get hasPermissionDiscount(): boolean {
    return this.permissions.canAccess(VACCINE_APPLICATIONS, VaccineApplicationsActions.DISCOUNT);
  }

  nextEnabled(): boolean {
    if (this.application && this.application.hasPaymentCovenantCharged()) {
      return true;
    } else if (this.application && this.application.payment instanceof CashPaymentForm) {
      const cp: CashPaymentForm = <CashPaymentForm>this.application.payment;
      return cp.getTotalPaid() - cp.getTotalChange() === this.application.netValue;
    } else if (this.application && this.application.payment instanceof DeferredPaymentForm) {
      const dp: DeferredPaymentForm = <DeferredPaymentForm>this.application.payment;
      return (
        Boolean(dp.installmentPolicy) &&
        Boolean(dp.firstExpiration) &&
        Boolean(dp.numberOfInstallments) &&
        Boolean(dp.periodicity)
      );
    }
    return false;
  }

  submitAndNext(): void {
    if (this.nextEnabled() && !this.loading) {
      this.loading = true;
      const successHandler = (a: VaccineApplication) => {
        this.showMessage('Registro salvo com sucesso');
        this.loading = false;
        this.service.finish(a);
        this.router.navigate(['../postFinish'], { relativeTo: this.route });
      };
      this.repository
        .insert(this.application)
        .subscribe(successHandler, err => (this.loading = false));
    } else {
      this.showMessage('Forma de pagamento inválida.');
    }
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }
}

<header>
  <img *ngIf="logoUrl !== null" [src]="logoUrl" />
  <h1>Histórico de Vacinação</h1>
</header>
<ng-container *ngIf="error != null">
  Não foi possível carregar os dados deste paciente: {{ error }}
</ng-container>
<ng-container *ngIf="error == null">
  <section class="patient">
    <div class="patientName">
      <label>Nome:</label>
      <span>{{ patient.name }}</span>
    </div>
    <div class="patientBirthdate">
      <label>Data de Nascimento:</label>
      <span>{{ patient.birthdate | date: 'shortDate' }}</span>
    </div>
  </section>
  <section class="vaccines" *ngIf="vaccinations?.length > 0">
    <table>
      <thead>
        <tr>
          <th colspan="4">Aplicações</th>
        </tr>
        <tr>
          <th class="colDate colAppDate">Data de Aplicação</th>
          <th class="colVaccineGroup">Grupo de Vacina</th>
          <th class="colVaccine">Vacina</th>
          <th class="colDose">Dose</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let vac of vaccinations">
          <td class="colDate colAppDate">{{ vac.date | date: 'shortDate' }}</td>
          <td class="colVaccineGroup">{{ vac.type.name }}</td>
          <td class="colVaccine">{{ vac.operatable.name }}</td>
          <td class="colDose">{{ vac.dose.name }}</td>
        </tr>
      </tbody>
    </table>
  </section>
  <section
    class="externalVaccineApplication"
    *ngIf="patient.externalVaccineApplication?.length > 0"
  >
    <table>
      <thead>
        <tr>
          <th colspan="5">Outras Aplicações</th>
        </tr>
        <tr>
          <th class="colDate colAppDate">Data de Aplicação</th>
          <th class="colLocalApplication">Local de aplicação</th>
          <th class="colVaccine">Vacina</th>
          <th class="colDose">Dose</th>
          <th class="colVaccineLot">Lote</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let vac of patient.externalVaccineApplication">
          <td class="colDate colAppDate">{{ vac.date | date: 'shortDate' }}</td>
          <td class="colLocalApplication">{{ vac.local }}</td>
          <td class="colVaccine">{{ vac.vaccineType.name }}</td>
          <td class="colDose">{{ vac.dose.name }}</td>
          <td class="colVaccineLot">{{ vac.vaccineLot || '-' }}</td>
        </tr>
      </tbody>
    </table>
  </section>
  <section
    class="emptyApplications"
    *ngIf="!(vaccinations?.length > 0 || patient.externalVaccineApplication?.length > 0)"
  >
    <div>Nenhum registro encontrado no historico de vacinação.</div>
  </section>
  <footer>
    <div>
      Declaramos que os dados informados acima são verdadeiros até a presente data e nos colocamos a
      disposição para quaisquer esclarecimento.
    </div>
    <div>Sem mais.</div>
    <div class="dateAndCity">Ponta Grossa, {{ date | date: 'longDate' }}</div>
  </footer>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DoctorsDetailsComponent } from './doctors-details/doctors-details.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [DoctorsDetailsComponent],
  entryComponents: [DoctorsDetailsComponent]
})
export class DoctorsModule {}

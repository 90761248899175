<app-layout-menu>
  <section class="actions">
    <button mat-icon-button (click)="list.invalidate()">
      <mat-icon>cached</mat-icon>
    </button>
  </section>
  <section class="center">
    <mat-form-field class="stateSelector">
      <mat-select [(value)]="stateSelected">
        <mat-option *ngFor="let state of states" [value]="state.value">
          {{ state.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>

  <entity-cards #list [repository]="repository" [perspective]="perspective" searchTerm="">
    <mat-card *entityCard="let entity" (click)="selectApplication(entity)">
      <mat-card-header>
        <div class="status" [matTooltip]="entity.date | date: 'short'">
          {{ entity.date | amTimeAgo }}
        </div>
        <mat-card-title> Aplicação nº {{ entity.number }} </mat-card-title>
        <mat-card-subtitle>{{ stateType(entity.state) }} </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-icon svgIcon="user"></mat-icon>
          <span> {{ entity.patient.name }} </span>
        </div>
        <div>
          <mat-icon svgIcon="syringe"></mat-icon>
          <span> {{ entity.vaccinesRequested }}</span>
        </div>
      </mat-card-content>
    </mat-card>
  </entity-cards>
</app-layout-menu>

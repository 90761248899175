<mat-sidenav-container>
  <mat-sidenav #sidemenu class="menu" mode="over" [(opened)]="menuOpened">
    <mat-toolbar (swipeleft)="menuOpened = false" color="primary">
      <mat-toolbar-row>
        <button mat-icon-button (click)="toggleMenu()"><mat-icon>arrow_back</mat-icon></button>
        <mat-icon class="mat-icon-logo" svgIcon="assets:logo_short_eceos_white"></mat-icon>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <button mat-icon-button>
          <mat-icon svgIcon="user-circle"></mat-icon>
        </button>
        <div class="user">
          <h4 class="name">{{ (currentUser.$user | async)?.name }}</h4>
          <small class="identifier">{{ currentUser.$principalStr | async }}</small>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="bg">
      <mat-toolbar id="actionbar" color="primary" class="toolbar">
        <button mat-icon-button (click)="sidemenu.open()">
          <mat-icon>menu</mat-icon>
        </button>

        <mat-icon
          routerLink="/"
          class="mat-icon-logo cursor-pointer"
          svgIcon="assets:logo_short_eceos_white"
        ></mat-icon>

        <span class="center">
          <ng-content select=".center"></ng-content>
        </span>

        <span>
          <ng-content select=".actions"></ng-content>
        </span>
      </mat-toolbar>
      <div class="body"><ng-content></ng-content></div>
      <div class="offline-notification">Você está sem conexão com a internet</div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

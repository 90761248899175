import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { VaccineTypeSummary, Doctor, BodySiteSummary, DoseSummary } from '..';
import { Operatable } from '../../operatables';

export class VaccinationHistory implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public date = new Date(),
    public type = new VaccineTypeSummary(),
    public operatable = new Operatable(),
    public doctor = new Doctor(),
    public bodySite = new BodySiteSummary(),
    public dose = new DoseSummary()
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: SgvJson.to.optional(this.type),
      operatable: SgvJson.to.optional(this.operatable),
      doctor: SgvJson.to.optional(this.doctor),
      bodySite: SgvJson.to.optional(this.bodySite),
      dose: SgvJson.to.optional(this.dose)
    });
  }

  static fromJson(data: any): VaccinationHistory {
    return SgvJson.from.simple(data, VaccinationHistory, {
      type: VaccineTypeSummary.fromJson(data.type),
      operatable: Operatable.fromJson(data.operatable),
      doctor: Doctor.fromJson(data.doctor),
      bodySite: BodySiteSummary.fromJson(data.bodySite),
      dose: DoseSummary.fromJson(data.dose)
    });
  }
}

<mat-table [dataSource]="data">
  <ng-container matColumnDef="tipo">
    <mat-header-cell *matHeaderCellDef>
      <label>TIPO DE VACINA</label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.vaccine.name }}</mat-cell>
  </ng-container>

  <ng-container *ngFor="let dose of doses" matColumnDef="{{ dose.id }}">
    <mat-header-cell *matHeaderCellDef>
      <label>{{ dose.name }}</label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="isSuggestion(element.getFromDose(dose))">
        <mat-card class="suggestion">
          <div>
            <label>Data Prevista</label>
            {{ element.getFromDose(dose).date | date: 'dd/MM/yyyy' }}
          </div>
        </mat-card>
      </div>

      <div *ngIf="isApplication(element.getFromDose(dose))">
        <mat-card class="application">
          <div>
            <label>Vacina</label>
            {{ element.getFromDose(dose).vaccine.name }}
          </div>
          <div>
            <label>Lote</label>
            {{ element.getFromDose(dose).lotNumber }}
          </div>
          <div>
            <label>Data</label>
            {{ element.getFromDose(dose).date | date: 'dd/MM/yyyy HH:mm:ss' }}
          </div>
          <div>
            <label>Médico</label>
            {{ element.getFromDose(dose).doctor?.name }}
          </div>
          <div>
            <label>Local de Ap.</label>
            {{ element.getFromDose(dose).applicationBodySite?.name }}
          </div>
        </mat-card>
      </div>

      <div *ngIf="isExternal(element.getFromDose(dose))">
        <mat-card class="external">
          <div>
            <label>Aplicação Externa</label>
          </div>
          <div>
            <label>Data</label>
            {{ element.getFromDose(dose).date | date: 'dd/MM/yyyy' }}
          </div>
          <div>
            <label>Local de Aplicação</label>
            {{ element.getFromDose(dose).local }}
          </div>
        </mat-card>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<app-layout-back>
  <section class="actions">
    <button mat-icon-button [matMenuTriggerFor]="opMenu" *ngIf="!isCanceledApplication">
      <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #opMenu="matMenu" yPosition="below">
      <button
        color="warn"
        mat-menu-item
        *ngIf="!isCanceledApplication"
        (click)="cancelApplication()"
      >
        Cancelar Aplicação
      </button>
    </mat-menu>
  </section>
  <mat-tab-group animationDuration="0s">
    <mat-tab label="Aplicação">
      <mat-card *ngIf="!isCanceledApplication">
        <mat-card-header>
          <mat-card-title>
            <label>APLICAÇÃO Nº {{ vaccineApplication.number }}</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="pendingApplication">
          <div class="patientBox">
            <mat-form-field class="patient">
              <mat-label>Paciente</mat-label>
              <input matInput [value]="vaccineApplication.patient?.name" readonly />
            </mat-form-field>
            <mat-form-field *ngIf="vaccineApplication.isOtherResponsible()" class="patient">
              <mat-label>Responsável</mat-label>
              <input
                matInput
                [value]="vaccineApplication.otherResponsible?.client?.name"
                readonly
              />
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="cancelCard" *ngIf="isCanceledApplication">
        <label class="lblcancel">Aplicação cancelada</label>
        <mat-card-header>
          <mat-card-title>
            <label>APLICAÇÃO Nº {{ vaccineApplication.number }}</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="pendingApplication">
          <div class="patientBox">
            <mat-form-field class="patient">
              <mat-label>Paciente</mat-label>
              <input matInput [value]="vaccineApplication.patient?.name" readonly />
            </mat-form-field>
            <mat-form-field *ngIf="vaccineApplication.isOtherResponsible()" class="patient">
              <mat-label>Responsável</mat-label>
              <input
                matInput
                [value]="vaccineApplication.otherResponsible?.client?.name"
                readonly
              />
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <label>ITENS</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="pendingApplication">
          <mat-table class="table" #table [dataSource]="items">
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef [ngClass]="'selectColumn'">
                <mat-checkbox
                  [disabled]="!isFinalizedApplication"
                  class="noripple"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'selectColumn'">
                <mat-checkbox
                  [disabled]="!isFinalizedApplication"
                  class="noripple"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef>Vacina</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.type.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="vaccine">
              <mat-header-cell *matHeaderCellDef>Produto</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.operatable.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="dose">
              <mat-header-cell *matHeaderCellDef>Dose</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.dose.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="doctor">
              <mat-header-cell *matHeaderCellDef>Médico</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.doctor.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef>Data da aplicação</mat-header-cell>
              <mat-cell *matCellDef="let element">{{
                vaccineApplication.date | date: 'shortDate'
              }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row #rowId *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-fab
            class="floatDefault"
            (click)="printBarcodes()"
            *ngIf="selection.selected.length > 0"
          >
            <mat-icon>print</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </mat-tab>
    <mat-tab label="Pagamento">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <label>PAGAMENTO</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="pendingPayment">
          <mat-form-field class="covenant">
            <mat-label>Convênio</mat-label>
            <input matInput [value]="vaccineApplication.paymentCovenant?.name" readonly />
          </mat-form-field>

          <mat-form-field class="total">
            <mat-label>Valor total</mat-label>
            <input
              matInput
              currencyMask
              [value]="vaccineApplication.total"
              [options]="{ align: 'left' }"
              readonly
            />
          </mat-form-field>
          <div *ngIf="!vaccineApplication.paymentPercentDiscount?.isEmpty()" class="discountBox">
            <mat-form-field class="totalcdiscount">
              <mat-label>Total com desconto</mat-label>
              <input
                matInput
                currencyMask
                [value]="vaccineApplication.netValue"
                [options]="{ align: 'left' }"
                readonly
              />
            </mat-form-field>
            <mat-form-field class="discountpcnt">
              <mat-label>Percentual de desconto</mat-label>
              <input
                matInput
                [value]="
                  vaccineApplication.paymentPercentDiscount?.getPercent(vaccineApplication.total) +
                  '%'
                "
                readonly
              />
            </mat-form-field>
            <mat-form-field class="discount">
              <mat-label>Desconto</mat-label>
              <input
                currencyMask
                matInput
                [value]="
                  vaccineApplication.paymentValueDiscount?.getValue(vaccineApplication.total)
                "
                [options]="{ align: 'left' }"
                readonly
              />
            </mat-form-field>
          </div>
          <ng-container *ngIf="vaccineApplication.netValue == 0" class="discountMsgWrapper">
            <div class="discountMsgWrapper">
              <label class="ptitle">Pagamento totalmente realizado por descontos</label>
            </div>
          </ng-container>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="vaccineApplication.isCashPaymentForm() && vaccineApplication.netValue != 0">
        <mat-card-header>
          <mat-card-title>
            <label>Pagamento à vista</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="pendingPayment">
          <ng-container>
            <ng-container *ngFor="let item of vaccineApplication.cashPaymentForm?.getPaid()">
              <div class="paymentBox" *ngIf="item.value !== 0">
                <mat-form-field class="paymentItem">
                  <mat-label>Documento Financeiro</mat-label>
                  <input matInput [value]="item.document.name" readonly />
                </mat-form-field>
                <mat-form-field class="paymentItem">
                  <mat-label>Valor Recebido</mat-label>
                  <input
                    currencyMask
                    matInput
                    [value]="item.value"
                    [options]="{ align: 'left' }"
                    readonly
                  />
                </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngFor="let item of vaccineApplication?.cashPaymentForm?.getChange()">
              <label class="ptitle">Troco</label>
              <div class="paymentBox">
                <mat-form-field class="paymentItem">
                  <mat-label>Documento Financeiro</mat-label>
                  <input matInput [value]="item.document.name" readonly />
                </mat-form-field>
                <mat-form-field class="paymentItem">
                  <mat-label>Valor Recebido</mat-label>
                  <input
                    currencyMask
                    matInput
                    [value]="item.value"
                    [options]="{ align: 'left' }"
                    readonly
                  />
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>
        </mat-card-content>
      </mat-card>
      <ng-container
        *ngIf="vaccineApplication?.isDeferredPaymentForm() && vaccineApplication.netValue != 0"
      >
        <mat-card>
          <mat-card-header>
            <mat-card-title>
              <mat-card-title>
                <label>Pagamento à prazo</label>
              </mat-card-title>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="pendingPayment">
            <div class="defferedBox">
              <div class="paymentBox">
                <label class="ptitle">Entrada</label>
                <ng-container *ngFor="let item of vaccineApplication?.deferredPaymentForm?.paid">
                  <div class="paymentLine" *ngIf="item.value !== 0">
                    <mat-form-field class="paymentItem">
                      <mat-label>Documento Financeiros</mat-label>
                      <input matInput [value]="item.document.name" readonly />
                    </mat-form-field>
                    <mat-form-field class="paymentItem">
                      <mat-label>Valor Recebido</mat-label>
                      <input
                        currencyMask
                        matInput
                        [value]="item.value"
                        [options]="{ align: 'left' }"
                        readonly
                      />
                    </mat-form-field>
                  </div>
                </ng-container>
                <ng-container *ngFor="let item of vaccineApplication?.deferredPaymentForm?.change">
                  <label class="ptitle">Troco</label>
                  <div class="paymentLine">
                    <mat-form-field class="paymentItem">
                      <mat-label>Documento financeiro</mat-label>
                      <input matInput [value]="item.document.name" readonly />
                    </mat-form-field>
                    <mat-form-field class="paymentItem">
                      <mat-label>Valor</mat-label>
                      <input
                        currencyMask
                        matInput
                        [value]="item.value"
                        [options]="{ align: 'left' }"
                        readonly
                      />
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
              <div class="parcelBox">
                <label class="ptitle">Parcelamento</label>
                <div class="paymentLine">
                  <mat-form-field class="paymentItem">
                    <mat-label>Valor a parcelar</mat-label>
                    <input
                      currencyMask
                      matInput
                      [value]="
                        vaccineApplication?.deferredPaymentForm?.getPendingPaid(
                          vaccineApplication.netValue
                        )
                      "
                      [options]="{ align: 'left' }"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field class="paymentItem">
                    <mat-label>Política de parcelamento</mat-label>
                    <input
                      matInput
                      [value]="vaccineApplication?.deferredPaymentForm?.installmentPolicy?.name"
                      readonly
                    />
                  </mat-form-field>
                </div>
                <div class="paymentLine">
                  <mat-form-field class="paymentItem">
                    <mat-label>Número de parcelas</mat-label>
                    <input
                      matInput
                      [value]="vaccineApplication?.deferredPaymentForm?.numberOfInstallments"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field class="paymentItem">
                    <mat-label>Primeira parcela</mat-label>
                    <input
                      matInput
                      [value]="
                        vaccineApplication?.deferredPaymentForm?.firstExpiration
                          | date: 'dd/MM/yyyy'
                      "
                      readonly
                    />
                  </mat-form-field>
                </div>
                <div class="paymentLine">
                  <mat-form-field class="paymentItem">
                    <mat-label>Recorre a cada</mat-label>
                    <input
                      matInput
                      [value]="vaccineApplication?.deferredPaymentForm?.periodicity?.recurrences"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field class="paymentItem">
                    <mat-label>Peridiocidade</mat-label>
                    <input
                      matInput
                      [value]="vaccineApplication?.periodicityType(types)?.name"
                      readonly
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </mat-tab>
    <mat-tab label="Fiscal" *ngIf="!isCanceledApplication && vaccineApplication?.netValue != 0">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <label>Opções</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngIf="!isNfeCanceled">
            <button
              mat-raised-button
              class="emit-btn"
              [disabled]="loading"
              *ngIf="!hasFiscalDocument"
              (click)="emitFiscalDocument()"
            >
              Emitir Nota Fiscal
            </button>
            <button
              mat-raised-button
              class="print-btn"
              [disabled]="loading"
              *ngIf="hasFiscalDocument"
              (click)="printFiscalDocument()"
            >
              Imprimir Nota Fiscal
            </button>
            <button
              mat-raised-button
              class="email-btn"
              [disabled]="loading"
              *ngIf="hasFiscalDocument"
              (click)="emailFiscalDocument()"
            >
              Enviar Nota Fiscal por e-mail
            </button>
          </ng-container>
          <div *ngIf="isCanceledApplication">
            <label class="notify">Nenhuma opção disponível</label>
          </div>
          <div *ngIf="isNfeCanceled">
            <label class="notify">A nota fical vinculada está cancelada</label>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</app-layout-back>

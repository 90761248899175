import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepositoryImpl, UrlSearchParamsBuilder, SgvJson } from '@eceos/arch';
import { VaccineTypeSummary } from './vaccine-type-summary';
import { Observable } from 'rxjs';
import { DoseSummary } from '..';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VaccineTypesRepository extends ReadonlyRepositoryImpl<
  VaccineTypeSummary,
  VaccineTypeSummary
> {
  constructor(api: ApiService) {
    super(api.root.path('/vaccines/vaccineTypes'), VaccineTypeSummary, VaccineTypeSummary);
  }
  findWithout(ids: string[]): Observable<VaccineTypeSummary[]> {
    return this.api
      .getJson({ params: new UrlSearchParamsBuilder().ignoreIds(ids).build() })
      .pipe(map(r => SgvJson.from.array(r, this.summaryType.fromJson)));
  }

  getDoses(type: VaccineTypeSummary): Observable<DoseSummary[]> {
    return this.api
      .path('/' + type.id + '/doses')
      .getJson()
      .pipe(map(r => SgvJson.from.array(r, DoseSummary.fromJson)));
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiPath, ApiService, AuthService, RequestMetadata, InterceptorConfig } from '@eceos/arch';
import { Observable, ReplaySubject, throwError, of } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { LoginValidator, LoginResult } from '@eceos/common-pages';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoginService implements LoginValidator {
  private api: ApiPath;

  private metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(apiRoot: ApiService, private router: Router, private authService: AuthService) {
    this.api = apiRoot.root.path('login');
  }

  validateLogin(username: string, password: string): Observable<LoginResult> {
    if (!username || !password) {
      return of(LoginResult.fail('Credenciais inválidas'));
    } else {
      const split = username.split('@');
      if (split.length !== 2) {
        return of(LoginResult.fail('Informe o usuário no padrão usuario@empresa'));
      } else {
        return this.getJwtToken(split[0], split[1], password);
      }
    }
  }

  private getJwtToken(user: string, enterprise: string, password: string): Observable<LoginResult> {
    return this.api
      .getText({
        headers: new HttpHeaders().set(
          'Authorization',
          `Basic ${btoa(`${user}@${enterprise}:${password}`)}`
        ),
        metadata: this.metadata
      })
      .pipe(
        map(token => {
          if (token) {
            this.authService.publishLogin(token);
            this.router.navigate(['/']);
            return LoginResult.success('Login efetuado com sucesso');
          } else {
            return LoginResult.fail('Falha ao efetuar o login');
          }
        }),
        catchError(e => of(LoginResult.fail('Falha ao efetuar o login')))
      );
  }
}

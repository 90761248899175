import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InterceptorConfig, RequestMetadata, SgvJson } from '@eceos/arch';
import { Doctor, DoctorsRepository } from '@eceos/domain';

@Component({
  selector: 'app-doctors-details',
  templateUrl: './doctors-details.component.html',
  styleUrls: ['./doctors-details.component.scss']
})
export class DoctorsDetailsComponent implements OnInit {
  form: FormGroup;
  doctor: Doctor = null;

  private defaultRequestMetadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Doctor,
    private dialogRef: MatDialogRef<DoctorsDetailsComponent>,
    private repository: DoctorsRepository,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.dialogRef.disableClose = true;

    this.createForm();
  }

  ngOnInit() {}

  onSave(): void {
    if (!this.disabledBtn) {
      this.doctor = new Doctor();
      this.doctor = this.fromJson(this.form.value);
      this.repository.insert(this.doctor, this.defaultRequestMetadata).subscribe(
        (d: Doctor) => {
          this.resetForm();
          this.doctor = d;
          this.showMessage('Médico salvo com sucesso');
          this.dialogRef.close(d);
        },
        (err: any) => {
          this.showError(err, `Erro, ao salvar o médico`);
        }
      );
    }
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get crm(): AbstractControl {
    return this.form.get('crm');
  }

  get disabledBtn(): boolean {
    return this.form.invalid || this.form.pristine;
  }

  private createForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      crm: ['', Validators.required]
    });
  }

  private fromJson(data: any): Doctor {
    return SgvJson.from.existing(data, this.doctor);
  }

  private resetForm() {
    this.form.reset();
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }

  private showError(e: Error, message: string) {
    if (e) {
      console.error(e);
    }
    this.showMessage(message);
  }
}

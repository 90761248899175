import { Component, Input, OnInit } from '@angular/core';
import { VaccineApplication } from '@eceos/domain';

@Component({
  selector: 'app-payment-discount',
  templateUrl: './payment-discount.component.html',
  styleUrls: ['./payment-discount.component.scss']
})
export class PaymentDiscountComponent implements OnInit {
  @Input() application: VaccineApplication;

  constructor() {}

  ngOnInit() {}

  get value(): number {
    return this.application.discount.getValue(this.application.total);
  }

  set value(value: number) {
    this.application.asPaymentValueDiscount(value);
  }

  get percent(): number {
    return this.application.discount.getPercent(this.application.total);
  }

  set percent(percent: number) {
    this.application.asPaymentPercentDiscount(percent);
  }
}

<h2 matDialogTitle>Enviar Nota Fiscal</h2>
<mat-dialog-content>
  <form>
    <mat-form-field>
      <mat-label>e-mail</mat-label>
      <input
        #mailInput
        matInput
        type="email"
        required
        email
        name="email"
        [formControl]="mail"
        (keydown.enter)="submit()"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button matDialogClose>Cancelar</button>
  <button
    mat-raised-button
    #sendBtn=""
    color="accent"
    [disabled]="mail.invalid"
    [matDialogClose]="mailInput.value"
  >
    Enviar
  </button>
</mat-dialog-actions>

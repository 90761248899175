import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VaccinationReceptionService } from './vaccination-reception.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-vaccination-reception',
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./vaccination-reception.component.scss']
})
export class VaccinationReceptionComponent implements OnInit {
  constructor(private route: ActivatedRoute, private service: VaccinationReceptionService) {}

  ngOnInit() {
    this.route.paramMap.pipe(map(params => params.get('pid'))).subscribe(pid => {
      this.service.findPatient(pid);
    });
  }
}

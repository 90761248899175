import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Filter, FilterOp, SortMode, SortOrder } from '@eceos/arch';
import { SimpleEntityListPerspective } from '@eceos/common-components';
import {
  VaccineApplicationsRepository,
  VaccineApplicationState,
  VaccineApplicationSummary
} from '@eceos/domain';

const filterOf = (state: VaccineApplicationState) => new Filter('state', FilterOp.EQ, state);
const filterEmpty = new Filter('state', FilterOp.NE, null);

@Component({
  selector: 'app-pending-vaccines-list',
  templateUrl: './pending-vaccines-list.component.html',
  styleUrls: ['./pending-vaccines-list.component.scss']
})
export class PendingVaccinesListComponent implements OnInit {
  public states = [
    { label: 'Todas as Vacinações', value: filterEmpty },
    {
      label: 'Vacinações Pendentes',
      value: filterOf(VaccineApplicationState.PENDING)
    },
    {
      label: 'Vacinações Pendentes Aplicação',
      value: filterOf(VaccineApplicationState.PENDING_APPLICATION)
    },
    {
      label: 'Vacinações Canceladas',
      value: filterOf(VaccineApplicationState.CANCELED)
    },
    {
      label: 'Vacinações Finalizadas',
      value: filterOf(VaccineApplicationState.FINALIZED)
    }
  ];
  private _stateSelected: Filter = this.states[2].value;

  public perspective = new SimpleEntityListPerspective(
    'default',
    [this._stateSelected],
    [new SortOrder('date', SortMode.DSC)]
  );

  constructor(public repository: VaccineApplicationsRepository, private router: Router) {}

  ngOnInit() {}

  get stateSelected() {
    return this._stateSelected;
  }
  set stateSelected(value: Filter) {
    this._stateSelected = value;
    this.perspective = new SimpleEntityListPerspective(
      this.perspective.name,
      value ? [value] : [],
      this.perspective.sorts
    );
  }

  stateType(state: string): string {
    switch (state) {
      case 'PENDING':
        return 'PENDENTE';
      case 'PENDING_APPLICATION':
        return 'APLICAÇÃO PENDENTE';
      case 'FINALIZED':
        return 'FINALIZADA';
      case 'CANCELED':
        return 'CANCELADA';
      default:
        return '';
    }
  }

  selectApplication(event: VaccineApplicationSummary): void {
    this.navigate(event);
  }

  private navigate(vaccine?: VaccineApplicationSummary) {
    if (vaccine) {
      this.router.navigate(['/pendingVaccines', vaccine.id]);
    }
  }
}

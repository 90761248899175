import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PendingVaccinesListComponent } from './pending-vaccines-list/pending-vaccines-list.component';
import { PendingVaccinesDetailsComponent } from './pending-vaccines-details/pending-vaccines-details.component';
import { AuthGuard } from '@eceos/arch';

export const routes: Routes = [
  {
    path: 'pendingVaccines',
    component: PendingVaccinesListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pendingVaccines/:id',
    component: PendingVaccinesDetailsComponent,
    canActivate: [AuthGuard]
  }
];

export const PendingVaccinesRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });

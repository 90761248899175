import { SgvId, SgvJson } from '@eceos/arch';
import { DoseSummary, Doctor, BodySiteSummary } from '../..';
import { Operatable } from '../../../operatables';

export abstract class VaccinationTimelineDoses {
  public id = SgvId.gen();
  public dose = new DoseSummary();
  public date = new Date();

  abstract toJson(): any;

  static fromJson(data: any): VaccinationTimelineDoses {
    if (data) {
      switch (data.type) {
        case 'suggestion':
          return SuggestionTimelineDoses.fromJson(data);
        case 'external':
          return ExternalApplicationTimelineDoses.fromJson(data);
        case 'application':
          return ApplicationTimelineDoses.fromJson(data);
      }
    }
    return void 0;
  }
}

export class ApplicationTimelineDoses extends VaccinationTimelineDoses {
  constructor() {
    super();
  }
  public doctor = new Doctor();
  public lotNumber: String;
  public applicationBodySite = new BodySiteSummary();
  public vaccine = new Operatable();

  toJson(): any {
    return SgvJson.to.simple(this, {
      dose: SgvJson.to.optional(this.dose),
      doctor: SgvJson.to.optional(this.doctor),
      applicationBodySite: SgvJson.to.optional(this.applicationBodySite),
      vaccine: SgvJson.to.optional(this.vaccine)
    });
  }

  static fromJson(data: any): ApplicationTimelineDoses {
    return SgvJson.from.simple(data, ApplicationTimelineDoses, {
      type: 'application',
      dose: DoseSummary.fromJson(data.dose),
      doctor: Doctor.fromJson(data.doctor),
      applicationBodySite: BodySiteSummary.fromJson(data.applicationBodySite),
      vaccine: Operatable.fromJson(data.vaccine)
    });
  }
}

export class SuggestionTimelineDoses extends VaccinationTimelineDoses {
  constructor() {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'suggestion',
      dose: SgvJson.to.optional(this.dose)
    });
  }

  static fromJson(data: any): SuggestionTimelineDoses {
    return SgvJson.from.simple(data, SuggestionTimelineDoses, {
      type: 'suggestion',
      dose: DoseSummary.fromJson(data.dose)
    });
  }
}

export class ExternalApplicationTimelineDoses extends VaccinationTimelineDoses {
  constructor() {
    super();
  }
  public local: String;

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'external',
      dose: SgvJson.to.optional(this.dose)
    });
  }

  static fromJson(data: any): ExternalApplicationTimelineDoses {
    return SgvJson.from.simple(data, ExternalApplicationTimelineDoses, {
      type: 'external',
      dose: DoseSummary.fromJson(data.dose)
    });
  }
}

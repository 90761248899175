<div class="line" *ngIf="value">
  <mat-form-field>
    <mat-label>Tipo</mat-label>
    <mat-select [value]="value.type" (valueChange)="onTypeChange($event)" [disabled]="disabled">
      <mat-option value="CELULAR">Celular</mat-option>
      <mat-option value="TELEFONE">Telefone</mat-option>
      <mat-option value="EMAIL">E-mail</mat-option>
      <mat-option value="EMAIL_NFE">Email NFe</mat-option>
    </mat-select>
  </mat-form-field>
  <div [ngSwitch]="value.type">
    <app-email-input
      #detail
      *ngSwitchCase="'EMAIL' || 'EMAIL_NFE'"
      [value]="value.detail"
      (valueChange)="onDetailChange($event, detail.invalid)"
      [disabled]="disabled"
    ></app-email-input>
    <app-phone-input
      #detail
      *ngSwitchCase="'TELEFONE'"
      [value]="value.detail"
      (valueChange)="onDetailChange($event, detail.invalid)"
      [disabled]="disabled"
    ></app-phone-input>
    <app-email-input
      #detail
      *ngSwitchCase="'EMAIL_NFE'"
      [value]="value.detail"
      (valueChange)="onDetailChange($event, detail.invalid)"
      [disabled]="disabled"
    ></app-email-input>
    <app-cell-phone-input
      #detail
      *ngSwitchCase="'CELULAR'"
      [value]="value.detail"
      (valueChange)="onDetailChange($event, detail.invalid)"
      [disabled]="disabled"
    ></app-cell-phone-input>
    <mat-form-field *ngSwitchDefault>
      <input matInput disabled />
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>Comentário</mat-label>
    <input
      matInput
      [value]="value.comment"
      (keyup)="onCommentChange($event.target.value)"
      [disabled]="disabled || !value.detail"
    />
  </mat-form-field>
</div>

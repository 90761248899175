<app-layout-back [loading]="loading" [hotkeys]="hotkeys">
  <section class="actions">
    <button
      mat-icon-button
      *ngIf="hasPermissionRequest && modeUpdate"
      matTooltip="(F3) Nova Ap. Externa"
      matTooltipPosition="before"
      [disabled]="form.invalid || paymentCovenant.invalid || paymentCovenantData.invalid || loading"
      (click)="newExternalVaccineApplication()"
    >
      <mat-icon>explicit</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="hasPermissionRequest && modeUpdate"
      matTooltip="(F2) Nova vacinação"
      matTooltipPosition="before"
      [disabled]="form.invalid || paymentCovenant.invalid || paymentCovenantData.invalid || loading"
      (click)="newReception()"
    >
      <mat-icon>colorize</mat-icon>
    </button>
  </section>
  <div class="content">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSave()" class="data-form">
          <mat-tab-group>
            <mat-tab label="Dados Cadastrais">
              <fieldset>
                <legend>Dados Pessoais</legend>
                <div class="row">
                  <section class="column">
                    <mat-form-field class="cell-name">
                      <mat-label>Nome</mat-label>
                      <input
                        matInput
                        formControlName="name"
                        (blur)="onSearchPatient()"
                        autofocus
                        focusDelay="200"
                      />
                      <mat-error *ngIf="name.invalid">O Nome é inválido</mat-error>
                    </mat-form-field>
                    <mat-form-field class="cell-date">
                      <mat-label>Data de Nascimento</mat-label>
                      <input
                        matInput
                        type="date"
                        formControlName="birthdate"
                        (blur)="onSearchPatient()"
                      />
                      <mat-error *ngIf="birthdate.invalid"
                        >A Data de Nascimento é inválida</mat-error
                      >
                      <mat-hint *ngIf="birthdate.valid">Idade {{ age }}</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="cell-cpf">
                      <mat-label>CPF</mat-label>
                      <input #cpfInput matInput [kzMask]="'999.999.999-99'" formControlName="cpf" />
                      <mat-error *ngIf="cpf.invalid">O CPF é inválido</mat-error>
                    </mat-form-field>
                    <mat-form-field class="cell-cns">
                      <mat-label>Número do CNS</mat-label>
                      <input #cnsInput matInput formControlName="cns" />
                    </mat-form-field>
                    <mat-form-field class="cell-gender">
                      <mat-label>Sexo</mat-label>
                      <mat-select formControlName="gender">
                        <mat-option *ngFor="let gender of genders" [value]="gender">
                          {{ gender.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </section>
                </div>
                <div class="row">
                  <section class="column">
                    <app-data-selector
                      label="Convênio"
                      [repository]="paymentCovenantsRepository"
                      [invalid]="paymentCovenant.invalid"
                      [formControl]="paymentCovenant"
                      (valueChange)="paymentCovenantSelected($event)"
                    >
                    </app-data-selector>
                    <app-payment-covenant-card-data-item
                      *ngIf="paymentCovenant.value?.providesRecord"
                      [invalid]="paymentCovenantData.invalid"
                      formControlName="paymentCovenantData"
                    >
                    </app-payment-covenant-card-data-item>
                  </section>
                </div>
              </fieldset>
              <fieldset>
                <legend>Endereço</legend>
                <div class="row">
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="zipCodeSelected($event)"
                    [displayWith]="displayZipcodeWith"
                  >
                    <mat-option *ngFor="let entity of zipCodes | async" [value]="entity">
                      {{ entity.zipCode }}
                    </mat-option>
                  </mat-autocomplete>
                  <div formGroupName="address" class="address">
                    <section class="zipCode">
                      <mat-form-field>
                        <mat-label>CEP</mat-label>
                        <input
                          matInput
                          [formControl]="zipCode"
                          (keyup)="onZipcodeKeyup($event)"
                          (blur)="onZipcodeBlur($event)"
                          (focus)="onZipcodeFocus($event)"
                          [matAutocomplete]="auto"
                        />
                        <mat-error *ngIf="zipCode.invalid">O CEP é inválido</mat-error>
                      </mat-form-field>
                      <app-data-selector
                        label="Municipio"
                        [repository]="citiesRepository"
                        [formControl]="city"
                        display="nameWithUf"
                      >
                      </app-data-selector>
                      <mat-form-field>
                        <mat-label>Bairro</mat-label>
                        <input matInput [formControl]="district" />
                        <mat-error *ngIf="district.invalid">O Bairro é inválido</mat-error>
                      </mat-form-field>
                      <mat-form-field class="cell-street">
                        <mat-label>Logradouro</mat-label>
                        <input matInput [formControl]="street" />
                        <mat-error *ngIf="street.invalid">O Logradouro é inválido</mat-error>
                      </mat-form-field>
                      <section class="sub-columns">
                        <mat-form-field class="cell-addr-nr">
                          <mat-label>Número</mat-label>
                          <input matInput [formControl]="number" />
                          <mat-error *ngIf="number.invalid">O Número é inválido</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                          <mat-label>Complemento</mat-label>
                          <input matInput [formControl]="details" />
                        </mat-form-field>
                      </section>
                    </section>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Contatos</legend>
                <div
                  formArrayName="contacts"
                  *ngFor="let contact of contacts.controls; let i = index"
                >
                  <div [formGroupName]="i">
                    <app-contacts
                      formControlName="contact"
                      (valueChange)="ensureEmptyContact()"
                    ></app-contacts>
                  </div>
                </div>
              </fieldset>
            </mat-tab>
            <mat-tab label="Histórico" *ngIf="modeUpdate && patient">
              <app-vaccination-histories [patient]="patient"></app-vaccination-histories>
            </mat-tab>
            <mat-tab label="Calendário" *ngIf="modeUpdate && patient">
              <app-vaccination-timeline [patient]="patient"></app-vaccination-timeline>
            </mat-tab>
            <mat-tab label="Observações" >
              <fieldset class="details first">
                <mat-form-field>
                  <textarea
                    matInput
                    formControlName="details"
                    [matTextareaAutosize]="true"
                    [matAutosizeMinRows]="10"
                    [matAutosizeMaxRows]="10"
                  ></textarea>
                </mat-form-field>
              </fieldset>
            </mat-tab>
          </mat-tab-group>
          <button mat-fab type="submit" class="floatDefault" [disabled]="disabledBtn || loading">
            <mat-icon>done</mat-icon>
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</app-layout-back>

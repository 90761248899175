import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive()
export abstract class ValueAccessorBase<T> implements ControlValueAccessor {
  private innerValue: T;
  @Output() valueChange = new EventEmitter<T>();
  @Output() touched = new EventEmitter();
  @Input() disabled = false;
  invalid = false;

  private valueChangeSubscription: Subscription;
  private touchSubscription: Subscription;

  get value(): T {
    return this.innerValue;
  }

  @Input()
  set value(value: T) {
    if (this.innerValue !== value) {
      if (!value) {
        this.innerValue = value;
        this.invalid = this.validate(value);
        this.publishChange();
      } else if (this.validate(value)) {
        this.innerValue = value;
        this.invalid = false;
        this.publishChange();
      } else {
        this.invalid = true;
      }
    }
  }

  protected validate(value: T): boolean {
    return true;
  }

  protected publishChange() {
    this.valueChange.emit(this.innerValue);
  }

  protected publishTouched() {
    this.valueChange.emit(this.innerValue);
  }

  writeValue(value: T) {
    this.innerValue = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
    this.valueChangeSubscription = this.valueChange.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    if (this.touchSubscription) {
      this.touchSubscription.unsubscribe();
    }
    this.touchSubscription = this.touched.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

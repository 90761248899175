import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@eceos/default-shared';
import { MailInputDialogComponent } from './mail-input-dialog.component';

@NgModule({
  imports: [CommonModule, DefaultSharedModule],
  declarations: [MailInputDialogComponent],
  entryComponents: [MailInputDialogComponent],
  exports: [MailInputDialogComponent],
})
export class MailInputDialogModule {}

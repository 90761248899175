import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { PatientsModule } from './patients/patients.module';
import { SettingsModule } from './settings/settings.module';

import { VaccinationReceptionModule } from './vaccination-reception/vaccination-reception.module';

import { PendingVaccinesModule } from './pending-vaccines/pending-vaccines.module';
import { DoctorsModule } from './doctors/doctors.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SettingsModule,
    DoctorsModule,
    PatientsModule,
    VaccinationReceptionModule,
    PendingVaccinesModule
  ],
  declarations: []
})
export class FeaturesModule {}

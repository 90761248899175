import { Component, forwardRef, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '@eceos/arch';
import { PaymentCovenantCardData } from '@eceos/domain';

@Component({
  selector: 'app-payment-covenant-card-data-item',
  templateUrl: './payment-covenant-card-data-item.component.html',
  styleUrls: ['./payment-covenant-card-data-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentCovenantCardDataItemComponent),
      multi: true
    }
  ]
})
export class PaymentCovenantCardDataItemComponent extends ValueAccessorBase<PaymentCovenantCardData>
  implements OnInit {
  @Input() invalid: boolean;

  constructor() {
    super();
  }

  ngOnInit() {}

  onCardNumberChange(value: string) {
    this.value.cardNumber = value;
    this.publishChange();
  }
}

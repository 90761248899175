import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { VaccinationReceptionService } from './../vaccination-reception.service';

@Injectable({ providedIn: 'root' })
export class VaccinationReceptionPaymentGuard implements CanActivate {
  constructor(private router: Router, private service: VaccinationReceptionService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.service.isValidApplication()) {
      this.router.navigate(['/patients']);
    }
    return true;
  }
}

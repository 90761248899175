<mat-form-field>
  <mat-label>Recorre a cada</mat-label>
  <input matInput type="number" [(ngModel)]="recurrences" [disabled]="disabled" />
</mat-form-field>
<mat-form-field>
  <mat-label>Periodicidade</mat-label>
  <mat-select [(ngModel)]="type" [disabled]="disabled">
    <mat-option *ngFor="let tp of types" [value]="tp">{{ tp.name }}</mat-option>
  </mat-select>
</mat-form-field>

import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { ConfirmDialogComponent } from '@eceos/common-components';
import {
  BaseNFeSummary,
  DailyPeriodicity,
  FiscalDocumentState,
  MonthlyPeriodicity,
  NfesRepository,
  VaccineApplication,
  VaccineApplicationItem,
  VaccineApplicationsRepository,
  VaccineApplicationState,
  WeeklyPeriodicity,
  YearlyPeriodicity,
  ReportFormat
} from '@eceos/domain';
import { LayoutBackComponent } from '../../../shared/layout/layout-back/layout-back.component';
import { MailInputDialogComponent } from '../../../shared/mail-input-dialog/mail-input-dialog.component';
import { SettingsRepository } from '../../settings/settings.repository';

@Component({
  selector: 'app-pending-vaccines-details',
  templateUrl: './pending-vaccines-details.component.html',
  styleUrls: ['./pending-vaccines-details.component.scss']
})
export class PendingVaccinesDetailsComponent implements OnInit {
  @ViewChild(LayoutBackComponent) layoutBack: LayoutBackComponent;
  id = '';
  vaccineApplication: VaccineApplication = new VaccineApplication();
  nfe: BaseNFeSummary;
  confirmDialog: MatDialogRef<ConfirmDialogComponent> = null;
  vaccine: VaccineApplicationItem = new VaccineApplicationItem();
  loading = false;
  items = new MatTableDataSource();
  selection = new SelectionModel<VaccineApplicationItem>(true, []);
  displayedColumns = ['select', 'type', 'vaccine', 'dose', 'doctor', 'date'];

  types = [
    {
      name: 'dias',
      action: () => new DailyPeriodicity()
    },
    {
      name: 'semanas',
      action: () => new WeeklyPeriodicity()
    },
    {
      name: 'meses',
      action: () => new MonthlyPeriodicity()
    },
    {
      name: 'anos',
      action: () => new YearlyPeriodicity()
    }
  ];

  private defaultRequestMetadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private location: Location,
    private repository: VaccineApplicationsRepository,
    public dialog: MatDialog,
    private nfesRepository: NfesRepository,
    private settingsRepository: SettingsRepository
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.detailsPending();
  }

  detailsPending() {
    this.loading = true;
    this.repository.find(this.id, this.defaultRequestMetadata).subscribe(
      s => {
        this.vaccineApplication = s;
        this.items.data = s.items;
        this.checkIfFiscalDocumentExists();
        this.loading = false;
      },
      e => {
        this.vaccineApplication = null;
        this.loading = false;
        this.showMessage('Aplicação não encontrada');
        this.location.back();
      }
    );
  }

  cancelApplication() {
    this.loading = true;
    this.confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Atenção',
        content: 'Tem certeza de que deseja cancelar a aplicação?'
      }
    });
    this.confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.repository
          .cancelApplication(this.vaccineApplication, this.defaultRequestMetadata)
          .subscribe(
            s => {
              this.showMessage('Aplicação cancelada com sucesso');
              this.loading = false;
              this.location.back();
            },
            e => {
              this.showError(e, ' Aplicação não pôde ser cancelada');
              this.loading = false;
            },
            () => {
              this.loading = false;
            }
          );
      }
      this.confirmDialog = null;
    });
    this.loading = false;
  }

  emitFiscalDocument() {
    if (!this.loading && !this.hasFiscalDocument) {
      this.loading = true;
      this.createAndTransmit();
    }
  }

  printFiscalDocument() {
    if (!this.loading && this.hasFiscalDocument) {
      if (this.hasDefaultPrinter) {
        this.loading = true;
        this.nfesRepository
          .printTo(
            this.nfe,
            this.settingsRepository.defaultReceiptPrinter,
            ReportFormat.PDF,
            this.defaultRequestMetadata
          )
          .subscribe(
            r => {
              this.showMessage('Impressão enviada com sucesso');
              this.loading = false;
            },
            e => {
              this.showError(e, 'Erro ao enviar impressão');
              this.loading = false;
            }
          );
      } else {
        this.showMessage('Impressora padrão não encontrada');
      }
    }
  }

  emailFiscalDocument() {
    if (!this.loading && this.hasFiscalDocument) {
      const dialogRef = this.dialog.open(MailInputDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loading = true;
          this.nfesRepository.mailTo(this.nfe, result, this.defaultRequestMetadata).subscribe(
            r => {
              this.showMessage(`Nota Fiscal enviada para ${result}`);
              this.loading = false;
            },
            e => {
              this.showError(e, 'Erro ao enviar nota fiscal');
              this.loading = true;
            }
          );
        }
      });
    }
  }

  get isCanceledApplication(): boolean {
    return this.vaccineApplication.state === VaccineApplicationState.CANCELED;
  }

  get isFinalizedApplication(): boolean {
    return this.vaccineApplication.state === VaccineApplicationState.FINALIZED;
  }

  get isNfeCanceled(): boolean {
    return this.nfe ? this.nfe.status === FiscalDocumentState.CANCELED : false;
  }

  get application(): VaccineApplication {
    return this.vaccineApplication;
  }

  get hasFiscalDocument(): boolean {
    return Boolean(this.nfe);
  }

  get hasDefaultPrinter(): boolean {
    return Boolean(this.settingsRepository.defaultReceiptPrinter);
  }

  private transmitFiscalDocument() {
    this.nfesRepository.transmit(this.nfe, this.defaultRequestMetadata).subscribe(
      r => {
        this.checkIfFiscalDocumentExists();
        if (!r.isSuccess) {
          this.showMessage(
            'Não foi possível transmitir a nota pelo PDR, acesse https://eceos.cloud para transmitir.'
          );
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      e => {
        this.showError(
          e,
          'Não foi possível transmitir essa nota pelo PDR, acesse https://eceos.cloud para transmitir.'
        );
        this.loading = false;
      }
    );
  }

  private createAndTransmit() {
    this.nfesRepository
      .createFromVaccineApplication(this.application, this.defaultRequestMetadata)
      .subscribe(
        nfe => {
          this.nfe = nfe;
          this.transmitFiscalDocument();
        },
        e => {
          this.showError(
            e,
            'Não foi possível gerar a nota pelo PDR, acesse https://eceos.cloud para gerar.'
          );
          this.loading = false;
        }
      );
  }

  private checkIfFiscalDocumentExists() {
    if (this.application) {
      this.nfesRepository
        .findSummaryByVaccineApplication(this.application)
        .subscribe(nfe => (this.nfe = nfe));
    }
  }

  printBarcodes() {
    if (!this.loading) {
      this.loading = true;
      if (!this.settingsRepository.hasDefaultReceiptPrinter()) {
        this.showMessage('A impressora está desconectada!');
        this.loading = false;
        return;
      }

      if (this.selection.selected.length === 0) {
        this.showMessage('Nenhum item selecionado para impressão!');
        this.loading = false;
        return;
      }

      this.showMessage(
        this.selection.selected.length + ' item(s) selecionado(s) para impressão! Enfileirando...'
      );

      this.repository
        .printLabels(
          this.settingsRepository.defaultReceiptPrinter,
          this.vaccineApplication,
          this.selection.selected,
          this.defaultRequestMetadata
        )
        .subscribe(
          result => {
            this.showMessage(
              this.selection.selected.length + ' item(s) foram enviados a impressão com sucesso!'
            );
            this.loading = false;
          },
          e => {
            this.showError(e, `Erro em impressões (${e.error}).`);
            this.loading = false;
          }
        );
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.items.data.forEach(row => this.selection.select(<VaccineApplicationItem>row));
  }

  private showError(e: Error, message: string) {
    if (e) {
      console.error(e);
    }
    this.showMessage(message);
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }
}

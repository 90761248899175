import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientVaccinationHistoryReportComponent } from './patient-vaccination-history-report/patient-vaccination-history-report.component';
import { PatientsListComponent } from './patients-list/patients-list.component';
import { AuthGuard } from '@eceos/arch';
import { PatientsDetailsComponent } from './patients-details/patients-details.component';

export const routes: Routes = [
  {
    path: 'patients',
    component: PatientsListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'patients/:id',
    component: PatientsDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'patients/:from/:id',
    component: PatientsDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'patients/:id/report/vaccinationHistory',
    component: PatientVaccinationHistoryReportComponent
  }
];

export const PatientsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });

import { SgvJson } from '@eceos/arch';

export class Contact {
  constructor(public type = '', public detail = '', public comment = '') {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Contact {
    return json ? SgvJson.from.simple(json, Contact) : null;
  }
}

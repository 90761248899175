import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SgvJson, RequestMetadata, InterceptorConfig } from '@eceos/arch';
import { Hotkey, HotkeySet } from '@eceos/common-utils';
import {
  ClientsRepository,
  ClientSummary,
  Doctor,
  OtherResponsible,
  Patient,
  PatientsRepository,
  PatientSummary,
  SelfResponsible,
  VaccineApplication,
  VaccineApplicationItem,
  VaccineApplicationsRepository
} from '@eceos/domain';
import { lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DoctorsDetailsComponent } from '../../doctors/doctors-details/doctors-details.component';
import { SettingsRepository } from '../../settings/settings.repository';
import { VaccinationReceptionService } from '../vaccination-reception.service';
import {
  PatientsDetailsFormComponent,
  PatientsDetailsFormComponentData,
  State
} from './../../patients/patients-details/patients-details-form/patients-details-form.component';

@Component({
  selector: 'app-vaccination-reception-data',
  templateUrl: './vaccination-reception-data.component.html',
  styleUrls: ['./vaccination-reception-data.component.scss']
})
export class VaccinationReceptionDataComponent implements OnInit, OnDestroy {
  hotkeys = HotkeySet.of([
    Hotkey.key('f2')
      .description('Novo paciente')
      .do(() => this.newPatient()),
    Hotkey.key('f3')
      .description('Novo médico')
      .do(() => this.newDoctor()),
    Hotkey.key('f8')
      .key('ctrl+enter')
      .description('Concluir e navegar para o pagamento')
      .do(() => this.save())
  ]);
  form: FormGroup;
  loading = false;
  patientDialog: MatDialogRef<PatientsDetailsFormComponent> = null;
  doctorDialog: MatDialogRef<DoctorsDetailsComponent> = null;

  types = [
    {
      name: 'Terceiros',
      value: 'other'
    },
    {
      name: 'Próprio',
      value: 'self'
    }
  ];

  private defaultRequestMetadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private service: VaccinationReceptionService,
    private vaccineApplicationRepository: VaccineApplicationsRepository,
    private dialog: MatDialog,
    public clientsRepository: ClientsRepository,
    private patientsRepository: PatientsRepository,
    private settingsRepository: SettingsRepository,
    private snackbar: MatSnackBar
  ) {
    this.createForm();

    if (this.service.isValidApplication()) {
      this.populateVaccineApplication(this.vaccineApplication);
      if (!this.responsibleByPatient.valid) {
        this.refreshResponsible();
      }
    }

    service.patientSubject
      .asObservable()
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((p: PatientSummary) => {
        this.service.initForPatient();
        this.refreshResponsible();
      });

    if (!this.settingsRepository.hasDefaultReceiptPrinter()) {
      this.snackbar.open('Atenção! A impressora está desconectada.', null, { duration: 6000 });
    }
    this.registerFormChangeListener();
  }

  get disabledBtn(): boolean {
    return !this.validForm() || this.form.pristine;
  }

  get vaccineApplication(): VaccineApplication {
    return this.service.vaccineApplication;
  }

  set vaccineApplication(vaccineApplication: VaccineApplication) {
    this.service.vaccineApplication = vaccineApplication;
  }

  get responsibleByPatient(): AbstractControl {
    return this.form.get('responsibleByPatient');
  }

  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  get type(): { name: string; value: string } {
    let type = this.types.find((t) => t.value === 'self');
    if (this.responsibleByPatient && this.responsibleByPatient.value && this.isOtherResponsible()) {
      type = this.types.find((t) => t.value === 'other');
    }
    return type;
  }

  ngOnInit() {
    this.hotkeys.enable();
  }

  ngOnDestroy() {
    this.hotkeys.disable();
  }

  newPatient(): void {
    if (!this.patientDialog) {
      this.patientDialog = this.dialog.open(PatientsDetailsFormComponent, {
        data: new PatientsDetailsFormComponentData(this.vaccineApplication.patient, State.INSERT)
      });

      this.patientDialog.afterClosed().subscribe((p: Patient) => {
        if (p) {
          this.vaccineApplication.responsibleByPatient = new OtherResponsible();
          this.vaccineApplicationRepository.getClientResponsibleByPatient(p.id).subscribe((c) => {
            (<OtherResponsible>this.vaccineApplication.responsibleByPatient).client = c;
            this.populateResponsibleByPatient(this.vaccineApplication);
          });
        }
        this.patientDialog = null;
      });
    }
  }

  editResponsiblePatientData(client: ClientSummary): void {
    if (!this.patientDialog) {
      const errorHandler = (err: any) => {
        this.showError(err, `Responsável não é um paciente.`);
      };
      this.patientsRepository
        .getPatientByClient(client, this.defaultRequestMetadata)
        .subscribe((patient) => {
          this.patientDialog = this.dialog.open(PatientsDetailsFormComponent, {
            data: new PatientsDetailsFormComponentData(patient, State.UPDATE)
          });

          this.patientDialog.afterClosed().subscribe((p: Patient) => {
            this.patientDialog = null;
          });
        }, errorHandler);
    }
  }

  newDoctor(): void {
    if (!this.doctorDialog) {
      this.doctorDialog = this.dialog.open(DoctorsDetailsComponent);

      this.doctorDialog.afterClosed().subscribe((d: Doctor) => {
        this.doctorDialog = null;
      });
    }
  }

  addNewItem() {
    const value = this.items.value
      .map((o: any) => o.item)
      .find((c: VaccineApplicationItem) => c && c.valid());
    const doctor: Doctor = value ? value.doctor : null;

    this.items.push(this.formBuilder.group({ item: new VaccineApplicationItem(doctor) }));
  }

  removeItem(index: number) {
    this.items.removeAt(index);
  }

  ensureEmptyItem() {
    const values = this.items.value as any[];
    const invalidItems = this.listInvalidItems();
    if (invalidItems.length === 0) {
      this.addNewItem();
    } else if (invalidItems.length > 1) {
      const last = invalidItems[invalidItems.length - 1];
      const idxToRemove = values.indexOf(values.filter((o) => o.item === last)[0]);
      this.removeItem(idxToRemove);
    }
  }

  async save() {
    if (!this.loading && this.validForm()) {
      await this.updateResponsible();

      this.loading = true;
      this.vaccineApplication = this.fromJson(this.form.value);
      this.loading = false;
      this.router.navigate(['../payment'], { relativeTo: this.route });
    }
  }

  fromJson(data: any): VaccineApplication {
    return SgvJson.from.existing(data, this.vaccineApplication, {
      items: data.items
        .filter(
          (c: any) =>
            c && c.item && c.item.type && c.item.operatable && c.item.dose && c.item.doctor
        )
        .map((c: any) => c.item)
    });
  }

  onTypeChange(type: { name: string; value: string }) {
    if (type.value === 'other') {
      this.responsibleByPatient.setValue(new OtherResponsible());
    } else if (type.value === 'self') {
      this.loading = true;
      this.responsibleByPatient.setValue(new SelfResponsible());
      this.patientsRepository.getSelfResponsibleBy(this.vaccineApplication.patient.id).subscribe(
        (c) => {
          (<SelfResponsible>this.vaccineApplication.responsibleByPatient).client = c;
          (<SelfResponsible>this.responsibleByPatient.value).client = c;
          this.loading = false;
        },
        (e) => {
          console.log('Erro ao carregar o cliente responsável próprio.');
          this.loading = false;
        }
      );
    }
  }

  isSelfResponsible() {
    return this.responsibleByPatient.value instanceof SelfResponsible;
  }

  isOtherResponsible() {
    return this.responsibleByPatient.value instanceof OtherResponsible;
  }

  onClientChange(c: ClientSummary) {
    if (this.isOtherResponsible) {
      (<OtherResponsible>this.responsibleByPatient.value).client = c;
      if (Boolean((<OtherResponsible>this.responsibleByPatient.value).client)) {
        this.responsibleByPatient.updateValueAndValidity();
      }
    }
  }

  private validForm(): boolean {
    return this.form.valid && this.hasValidItems() && !this.hasIncompleteItems();
  }

  private listInvalidItems(): VaccineApplicationItem[] {
    return this.items.value
      .map((o: any) => o.item)
      .filter((c: VaccineApplicationItem) => !c || !c.valid()) as VaccineApplicationItem[];
  }

  private hasValidItems(): boolean {
    const list = this.items.value
      .map((o: any) => o.item)
      .filter((c: VaccineApplicationItem) => c && c.valid()) as VaccineApplicationItem[];

    return list.length > 0;
  }

  private hasIncompleteItems(): boolean {
    const list = this.items.value
      .map((o: any) => o.item)
      .filter((c: VaccineApplicationItem) => c && c.incomplete()) as VaccineApplicationItem[];

    return list.length > 0;
  }

  private showError(e: Error, message: string) {
    console.log(e);
    this.snackbar.open(message, null, { duration: 1000 });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      date: [new Date(), Validators.required],
      responsibleByPatient: [new SelfResponsible(), this.validateResponsibleByPatient],
      items: this.formBuilder.array([
        this.formBuilder.group({ item: new VaccineApplicationItem() })
      ])
    });
  }

  private validateResponsibleByPatient(control: FormControl): ValidationErrors {
    const value = control.value;
    if (value && value.isValid()) {
      return null;
    }

    return {
      validateResponsibleByPatient: {
        valid: false
      }
    };
  }

  private populateVaccineApplication(a: VaccineApplication) {
    this.form.patchValue({
      date: a.date,
      responsibleByPatient: a.responsibleByPatient,
      items: a.items.map((i) => {
        return {
          item: i
        };
      })
    });

    this.addNewItem();
  }

  private registerFormChangeListener() {
    this.form.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((v) => {
      this.loading = true;
      this.vaccineApplication = this.fromJson(v);
      this.loading = false;
    });
  }

  private refreshResponsible() {
    this.vaccineApplicationRepository
      .getResponsibleByPatient(this.vaccineApplication.patient.id)
      .subscribe((rp) => {
        this.service.vaccineApplication.responsibleByPatient = rp;
        this.form.patchValue({
          responsibleByPatient: rp
        });
      });
  }

  private populateResponsibleByPatient(a: VaccineApplication) {
    this.form.patchValue({
      responsibleByPatient: a.responsibleByPatient
    });

    this.addNewItem();
  }

  private async updateResponsible() {
    if (!this.vaccineApplication.responsibleByPatient.isValid()) {
      if (this.vaccineApplication.isOtherResponsible()) {
        console.log('Não tem um responsável válido informado.');
      } else if (this.vaccineApplication.isSelfResponsible()) {
        this.loading = true;
        this.responsibleByPatient.setValue(new SelfResponsible());
        try {
          const client = await lastValueFrom(this.patientsRepository
            .getSelfResponsibleBy(this.vaccineApplication.patient.id));
          (this.vaccineApplication.responsibleByPatient as SelfResponsible).client = client;
          (this.responsibleByPatient.value as SelfResponsible).client = client;
          this.loading = false;
        } catch (e) {
          console.log('Erro ao carregar o cliente responsável próprio.');
          this.loading = false;
        }
      }
    }
  }
}

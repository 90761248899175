import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../../shared/shared.module';
import { CashPaymentComponent } from './cash-payment/cash-payment.component';
import { DeferredPaymentComponent } from './deferred-payment/deferred-payment.component';
import { PeriodicitiesComponent } from './deferred-payment/periodicities/periodicities.component';
import { PaybackTableComponent } from './payback-table/payback-table.component';
import { PaymentDiscountComponent } from './payment-discount/payment-discount.component';
import { PaymentDocumentValueComponent } from './payment-document-value/payment-document-value.component';
import { PaymentLabelValueComponent } from './payment-label-value/payment-label-value.component';
import { PaymentTableComponent } from './payment-table/payment-table.component';
import { VaccinationReceptionPaymentComponent } from './vaccination-reception-payment.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    CashPaymentComponent,
    DeferredPaymentComponent,
    PaybackTableComponent,
    PaymentDiscountComponent,
    PaymentDocumentValueComponent,
    PaymentLabelValueComponent,
    PaymentTableComponent,
    PeriodicitiesComponent,
    VaccinationReceptionPaymentComponent
  ]
})
export class VaccinationReceptionPaymentModule {}

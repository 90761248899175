import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiService,
  InterceptorConfig,
  RepositoryImpl,
  RequestMetadata,
  RequestMetadataUtils,
  UrlSearchParamsBuilder,
  FilterOp,
  Filter
} from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExternalVaccineApplication, Patient, PatientSummary } from '.';
import { ClientSummary } from '../../clients';
import { VaccinationHistory } from './vaccination-history';
import { VaccinationTimeline } from './vaccination-timeline';

@Injectable({ providedIn: 'root' })
export class PatientsRepository extends RepositoryImpl<PatientSummary, Patient> {
  constructor(api: ApiService) {
    super(api.root.path('/vaccines/patients'), PatientSummary, Patient);
  }

  getPatientBy(name: string, birthdate: Date): Observable<Patient> {
    return this.api
      .path('/one')
      .getJson({
        params: new HttpParams().set('name', name).set('birthdate', birthdate.getTime().toString())
      })
      .pipe(map(r => Patient.fromJson(r)));
  }

  getPatientByClient(
    client: ClientSummary,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<PatientSummary> {
    return this.api
      .path('fromClient')
      .path(client.id)
      .getJson({ metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map(r => PatientSummary.fromJson(r)));
  }

  listVaccinationHistoriesFor(patient: Patient | PatientSummary): Observable<VaccinationHistory[]> {
    return this.api
      .path('/application/histories/forPatient/' + patient.id)
      .getJson()
      .pipe(map(r => (r as any[]).map((j: any) => VaccinationHistory.fromJson(j))));
  }

  getSelfResponsibleBy(id: string): Observable<ClientSummary> {
    return this.api
      .path(id)
      .path('/selfResponsible')
      .getJson()
      .pipe(map(r => ClientSummary.fromJson(r)));
  }

  listVaccinationTimeline(patient: Patient): Observable<VaccinationTimeline[]> {
    return this.api
      .path(patient.id + '/vaccinationTimeline')
      .getJson()
      .pipe(map(r => (r as any[]).map((j: any) => VaccinationTimeline.fromJson(j))));
  }

  externalApplication(
    patient: Patient,
    externalVaccineApplication: ExternalVaccineApplication,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }
  ): Observable<Response> {
    return this.api
      .path(patient.id)
      .path('/externalApplication')
      .postJson(externalVaccineApplication.toJson(), {
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      });
  }

  listPatientsBy(name: string, birthdate: Date): Observable<Patient[]> {
    return this.api.getJson({
      params: new UrlSearchParamsBuilder()
        .filters([
          new Filter('name', FilterOp.EQ, name),
          new Filter('birthdate', FilterOp.EQ, birthdate.getTime().toString())
        ])
        .actives()
        .build()
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Periodicity,
  DailyPeriodicity,
  WeeklyPeriodicity,
  MonthlyPeriodicity,
  YearlyPeriodicity
} from '@eceos/domain';

@Component({
  selector: 'app-periodicities',
  templateUrl: './periodicities.component.html',
  styleUrls: ['./periodicities.component.scss']
})
export class PeriodicitiesComponent implements OnInit {
  @Input() periodicity: Periodicity = undefined;
  @Input() disabled: boolean;

  @Output() periodicityChange = new EventEmitter<Periodicity>();

  types = [
    {
      name: 'dias',
      action: () => new DailyPeriodicity()
    },
    {
      name: 'semanas',
      action: () => new WeeklyPeriodicity()
    },
    {
      name: 'meses',
      action: () => new MonthlyPeriodicity()
    },
    {
      name: 'anos',
      action: () => new YearlyPeriodicity()
    }
  ];

  constructor() {}

  ngOnInit() {}

  get type(): { name: string; action: () => Periodicity } {
    return this.periodicity !== undefined
      ? this.types.find(t => t.action().constructor.name === this.periodicity.constructor.name)
      : undefined;
  }

  set type(type) {
    const recurrences = this.periodicity ? this.periodicity.recurrences : 1;
    this.periodicity = type.action();
    this.periodicity.recurrences = recurrences;
    this.periodicityChange.emit(this.periodicity);
  }

  get recurrences(): number {
    return this.periodicity ? this.periodicity.recurrences : undefined;
  }

  set recurrences(recurrences: number) {
    if (this.periodicity) {
      this.periodicity.recurrences = recurrences;
    }
  }
}

import { Injectable } from '@angular/core';
import {
  PatientSummary,
  VaccineApplication,
  PatientsRepository,
  PaymentCovenantsRepository,
  PaymentCovenantSummary
} from '@eceos/domain';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VaccinationReceptionService {
  patientSubject = new Subject<PatientSummary>();
  vaccineApplication: VaccineApplication = undefined;
  finishReception = false;
  private patient: PatientSummary = null;

  constructor(
    private patientesRepository: PatientsRepository,
    private paymentCovenantsRepository: PaymentCovenantsRepository
  ) {}

  initForPatient() {
    if (
      this.patient &&
      this.vaccineApplication &&
      this.vaccineApplication.patient &&
      this.vaccineApplication.patient.id === this.patient.id &&
      !this.finishReception
    ) {
      return this.vaccineApplication;
    } else {
      this.vaccineApplication = new VaccineApplication(this.patient);

      this.paymentCovenantsRepository
        .getPaymentCovenantForPatient(this.patient.id)
        .subscribe((pc: PaymentCovenantSummary) => {
          this.vaccineApplication.paymentCovenant = pc;
        });

      this.finishReception = false;
    }
  }

  findPatient(pid: string) {
    if (this.patient && this.patient.id === pid) {
      return;
    }

    this.patientesRepository.findSummary(pid).subscribe((patient: PatientSummary) => {
      this.patient = patient;
      this.patientSubject.next(this.patient);
    });
  }

  isValidApplication(): boolean {
    return (
      this.vaccineApplication &&
      this.vaccineApplication.items &&
      this.vaccineApplication.items.length > 0 &&
      !this.finishReception
    );
  }

  isValidFinish(): boolean {
    return (
      this.vaccineApplication &&
      this.vaccineApplication.items &&
      this.vaccineApplication.items.length > 0 &&
      this.finishReception
    );
  }

  isValidPatient(): boolean {
    return Boolean(this.patient) && Boolean(this.patient.id);
  }

  finish(a: VaccineApplication) {
    this.vaccineApplication = a;
    this.patient = null;
    this.finishReception = true;
  }
}

import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { Doctor } from './doctor';

@Injectable({
  providedIn: 'root'
})
export class DoctorsRepository extends RepositoryImpl<Doctor, Doctor> {
  constructor(api: ApiService) {
    super(api.root.path('vaccines/doctors'), Doctor, Doctor);
  }
}

import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Gender } from '../../core';
import { BrazilianAddress } from '../../core/address';
import { PaymentCovenantData, PaymentCovenantDefaultData } from '../paymentCovenants';
import { PaymentCovenantSummary } from './../paymentCovenants/payment-covenant';
import { Contact } from './contact';
import { ExternalVaccineApplication } from './external-vaccine-application';

export class Patient implements DomainEntity {
  public readonly id = SgvId.gen();
  public name = '';
  public cpf = '';
  public details = '';
  public gender = Gender.NOT_INFORMED;
  public birthdate: Date = null;
  public address = new BrazilianAddress();
  public contacts: Contact[] = [];
  public paymentCovenant: PaymentCovenantSummary = null;
  public paymentCovenantData: PaymentCovenantData = new PaymentCovenantDefaultData();
  public externalVaccineApplication: ExternalVaccineApplication[] = [];
  public cnsNumber: string = null;

  constructor(address?: BrazilianAddress, contacts?: Contact[]) {
    this.address = Boolean(address) ? address : new BrazilianAddress();
    this.contacts = Boolean(contacts) ? contacts : [];
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      gender: this.gender.name,
      contacts: SgvJson.to.array(this.contacts),
      address: SgvJson.to.optional(this.address),
      paymentCovenant: this.paymentCovenant ? this.paymentCovenant.toJson() : null,
      paymentCovenantData: SgvJson.to.optional(this.paymentCovenantData)
    });
  }

  static fromJson(data: any): Patient {
    return SgvJson.from.simple(data, Patient, {
      gender: Gender.get(data.gender),
      contacts: SgvJson.from.array(data.contacts, Contact.fromJson),
      address: BrazilianAddress.fromJson(data.address),
      paymentCovenant: PaymentCovenantSummary.fromJson(data.paymentCovenant),
      paymentCovenantData: PaymentCovenantData.fromJson(data.paymentCovenantData),
      externalVaccineApplication: data.externalVaccineApplication
        ? data.externalVaccineApplication.map((ex: any) => ExternalVaccineApplication.fromJson(ex))
        : null
    });
  }
}

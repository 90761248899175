<h2 mat-dialog-title>{{ title }}</h2>
<form [formGroup]="form" (ngSubmit)="onSave()" class="data-form">
  <mat-dialog-content>
    <fieldset>
      <legend>Dados Pessoais</legend>
      <div class="row">
        <section class="column">
          <mat-form-field class="cell-name">
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" autofocus focusDelay="200" />
            <mat-error *ngIf="name.invalid">O Nome é inválido</mat-error>
          </mat-form-field>
          <mat-form-field class="cell-date">
            <mat-label>Data de Nascimento</mat-label>
            <input matInput type="date" formControlName="birthdate" />
            <mat-error *ngIf="birthdate.invalid">A Data de Nascimento é inválida</mat-error>
            <mat-hint *ngIf="birthdate.valid">Idade {{ age }}</mat-hint>
          </mat-form-field>
          <mat-form-field class="cell-cpf">
            <mat-label>CPF</mat-label>
            <input #cpfInput matInput [kzMask]="'999.999.999-99'" formControlName="cpf" />
            <mat-error *ngIf="cpf.invalid">O CPF é inválido</mat-error>
          </mat-form-field>
          <mat-form-field class="cell-gender">
            <mat-label>Sexo</mat-label>
            <mat-select formControlName="gender">
              <mat-option *ngFor="let gender of genders" [value]="gender">
                {{ gender.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </section>
      </div>
    </fieldset>
    <fieldset>
      <legend>Endereço</legend>
      <div class="row">
        <div formGroupName="address" class="address">
          <section class="zipCode">
            <app-data-selector
              label="CEP"
              (valueChange)="zipCodeSelected($event)"
              [repository]="zipCodesRepository"
              display="zipCode"
              [formControl]="zipCode"
            ></app-data-selector>
            <app-data-selector
              label="Municipio"
              [repository]="citiesRepository"
              [formControl]="city"
              display="nameWithUf"
            >
            </app-data-selector>
            <mat-form-field>
              <mat-label>Bairro</mat-label>
              <input matInput placeholder="" [formControl]="district" />
              <mat-error *ngIf="district.invalid">O Bairro é inválido</mat-error>
            </mat-form-field>
            <mat-form-field class="cell-street">
              <mat-label>Logradouro</mat-label>
              <input matInput placeholder="" [formControl]="street" />
              <mat-error *ngIf="street.invalid">O Logradouro é inválido</mat-error>
            </mat-form-field>
            <section class="sub-columns">
              <mat-form-field class="cell-addr-nr">
                <mat-label>Número</mat-label>
                <input matInput placeholder="" [formControl]="number" />
                <mat-error *ngIf="number.invalid">O Número é inválido</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Complemento</mat-label>
                <input matInput placeholder="" [formControl]="details" />
              </mat-form-field>
            </section>
          </section>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Contatos</legend>
      <div formArrayName="contacts" *ngFor="let contact of contacts.controls; let i = index">
        <div [formGroupName]="i">
          <app-contacts
            formControlName="contact"
            (valueChange)="ensureEmptyContact()"
          ></app-contacts>
        </div>
      </div>
    </fieldset>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="accent" type="submit" tabindex="1" [disabled]="disabledBtn">
      Salvar
    </button>
    <button mat-button color="warn" mat-dialog-close>Fechar</button>
  </mat-dialog-actions>
</form>

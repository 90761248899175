import { Injectable } from '@angular/core';
import { RemotePrinterSummary, RemotePrintersRepository } from '@eceos/domain';

const defaultReceiptPrinterKey = 'defaultPrinter.receipts';

@Injectable({ providedIn: 'root' })
export class SettingsRepository {
  private _defaultReceiptPrinter: RemotePrinterSummary;

  constructor(remotePrinterRepository: RemotePrintersRepository) {
    this.loadDefaultReceiptPrinter();
  }

  get defaultReceiptPrinter(): RemotePrinterSummary {
    return this._defaultReceiptPrinter;
  }

  set defaultReceiptPrinter(printer: RemotePrinterSummary) {
    if (printer !== this._defaultReceiptPrinter) {
      if (printer) {
        localStorage.setItem(defaultReceiptPrinterKey, JSON.stringify(printer.toJson()));
      } else {
        localStorage.removeItem(defaultReceiptPrinterKey);
      }
      this._defaultReceiptPrinter = printer;
    }
  }

  hasDefaultReceiptPrinter(): boolean {
    return Boolean(this._defaultReceiptPrinter);
  }

  private loadDefaultReceiptPrinter() {
    try {
      const json = localStorage.getItem(defaultReceiptPrinterKey);
      if (json) {
        this._defaultReceiptPrinter = RemotePrinterSummary.fromJson(JSON.parse(json));
      }
    } catch (e) {
      console.error('Falha ao carregar a impressora padrão', e);
    }
  }
}

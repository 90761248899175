import { Component, OnInit, Input } from '@angular/core';
import { CurrentUserService } from '@eceos/domain';

@Component({
  selector: 'app-layout-menu',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.scss']
})
export class LayoutMenuComponent implements OnInit {
  @Input() loading = false;

  menuOpened = false;

  constructor(public currentUser: CurrentUserService) {}

  ngOnInit() {}

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { CashPaymentForm } from '@eceos/domain';

@Component({
  selector: 'app-cash-payment',
  templateUrl: './cash-payment.component.html',
  styleUrls: ['./cash-payment.component.scss']
})
export class CashPaymentComponent implements OnInit {
  @Input() cashPaymentForm: CashPaymentForm;
  @Input() toPay: number;

  constructor() {}

  ngOnInit() {}

  get pending(): number {
    return this.cashPaymentForm ? this.cashPaymentForm.getPendingPaid(this.toPay) : 0;
  }

  clearPayback(): void {
    if (this.cashPaymentForm.getTotalPaid() <= this.toPay) {
      this.cashPaymentForm.getChange().forEach(e => this.cashPaymentForm.removeChange(e));
    }
  }
}

<div class="row">
  <div class="columns">
    <mat-form-field id="label">
      <input matInput value="Desconto" readonly tabindex="-1" />
    </mat-form-field>
    <mat-form-field id="percent">
      <mat-label>Desconto em %</mat-label>
      <input matInput type="number" [(ngModel)]="percent" max="100" />
      <span matSuffix>%</span>
    </mat-form-field>
    <mat-form-field id="value">
      <mat-label>Desconto em R$</mat-label>
      <input matInput currencyMask [(ngModel)]="value" />
    </mat-form-field>
  </div>
</div>

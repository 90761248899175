<app-layout-menu>
  <section class="content">
    <mat-card>
      <mat-card-title>Configurações</mat-card-title>
      <mat-card-content>
        <app-printer-settings></app-printer-settings>
      </mat-card-content>
    </mat-card>
  </section>
</app-layout-menu>

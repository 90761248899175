import { Component, ElementRef, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import {
  PatientSummary,
  Gender,
  ZipCode,
  Patient,
  PatientsRepository,
  ZipCodesRepository,
  CitiesRepository,
  Contact,
  BrazilianAddress
} from '@eceos/domain';
import { Observable, Subject, of } from 'rxjs';
import { SgvJson, InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { EceosValidators } from '@eceos/common-utils';

export enum State {
  INSERT,
  UPDATE
}

export class PatientsDetailsFormComponentData {
  public patient: PatientSummary = undefined;
  public state: State = State.INSERT;

  constructor(patient: PatientSummary, state: State) {
    this.patient = patient;
    this.state = state;
  }
}

@Component({
  selector: 'app-patients-details-form',
  templateUrl: './patients-details-form.component.html',
  styleUrls: ['./patients-details-form.component.scss']
})
export class PatientsDetailsFormComponent implements OnInit {
  @ViewChild('cpfInput') cpfInput: ElementRef & HTMLInputElement;
  @ViewChildren(MatAutocompleteTrigger) acFields: MatAutocompleteTrigger[];

  state: State;
  form: FormGroup;
  genders = Gender.values();
  zipCodes: Observable<ZipCode[]>;
  zipCodeQuery = new Subject<string>();

  patient: Patient = null;

  private defaultRequestMetadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PatientsDetailsFormComponentData,
    private dialogRef: MatDialogRef<PatientsDetailsFormComponent>,
    private repository: PatientsRepository,
    public zipCodesRepository: ZipCodesRepository,
    public citiesRepository: CitiesRepository,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.state = data.state;

    this.dialogRef.disableClose = true;
    this.zipCodes = this.zipCodeQuery.asObservable().pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(q => {
        if (q.length >= 5) {
          return this.zipCodesRepository.list(q);
        } else {
          return of([]);
        }
      })
    );

    this.createForm();

    setTimeout(() => {
      this.repository.find(this.data.patient.id).subscribe((patient: Patient) => {
        this.patient = patient;
        if (this.data.state === State.UPDATE) {
          this.updateGeneralData(patient);
        }
        this.updateAddress(patient.address);
        this.updateContacts(patient.contacts);
      });
    }, 200);
  }

  ngOnInit() {}

  fromJson(data: any): Patient {
    return SgvJson.from.existing(data, this.patient, {
      birthdate: moment(data.birthdate, 'YYYY-MM-DD'),
      gender: data.gender,
      address: SgvJson.from.existing(data.address, this.patient.address, {
        zipCode: SgvJson.from.existing(data.address.zipCode.zipCode, this.patient.address.zipCode, {
          zipCode: data.address.zipCode.zipCode.zipCode,
          city: data.address.zipCode.zipCode.city
        })
      }),
      contacts: data.contacts
        .filter((c: any) => c && c.contact && c.contact.type && c.contact.detail)
        .map((c: any) => c.contact)
    });
  }

  onSave() {
    if (!this.disabledBtn) {
      const errorHandler = (err: any) => {
        this.showError(err, `Erro, ao salvar o paciente`);
      };

      const successHandler = (p: Patient) => {
        this.resetForm();
        this.patient = p;
        this.showMessage('Registro salvo com sucesso');
        this.dialogRef.close(p);
      };

      if (this.state === State.INSERT) {
        this.patient = new Patient();
        this.patient = this.fromJson(this.form.value);
        this.repository
          .insert(this.patient, this.defaultRequestMetadata)
          .subscribe(successHandler, errorHandler);
      } else {
        this.patient = this.fromJson(this.form.value);
        this.repository
          .update(this.patient, this.defaultRequestMetadata)
          .subscribe(successHandler, errorHandler);
      }
    }
  }

  zipCodeKeypress(input: HTMLInputElement) {
    this.zipCodeQuery.next(input.value);
  }

  zipCodeSelected(value: ZipCode): void {
    this.populateZipCode(value);
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }
  get birthdate(): AbstractControl {
    return this.form.get('birthdate');
  }
  get cpf(): AbstractControl {
    return this.form.get('cpf');
  }
  get number(): AbstractControl {
    return this.form.get('address.number');
  }
  get details(): AbstractControl {
    return this.form.get('address.details');
  }
  get zipCode(): AbstractControl {
    return this.form.get('address.zipCode.zipCode');
  }
  get city(): AbstractControl {
    return this.form.get('address.zipCode.city');
  }
  get district(): AbstractControl {
    return this.form.get('address.zipCode.district');
  }
  get street(): AbstractControl {
    return this.form.get('address.zipCode.street');
  }
  get gender(): AbstractControl {
    return this.form.get('gender');
  }
  get contacts(): FormArray {
    return this.form.get('contacts') as FormArray;
  }

  get disabledBtn(): boolean {
    return this.form.invalid || this.form.pristine;
  }

  get age(): string {
    const m = moment(this.birthdate.value, 'YYYY-MM-DD');
    if (m.isValid()) {
      const now = moment();
      const years = now.diff(m, 'year');
      m.add(years, 'years');
      const months = now.diff(m, 'months');
      m.add(months, 'months');
      const days = now.diff(m, 'days');
      if (years === 0 && months === 0 && days === 0) {
        return '0d';
      }

      return this.ageValue(years, 'a ') + this.ageValue(months, 'm ') + this.ageValue(days, 'd');
    }

    return '';
  }

  private ageValue(value: number, label: string): string {
    return value > 0 ? value + label : '';
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 1000 });
  }

  private showError(e: Error, message: string) {
    console.log(e);
    this.snackbar.open(message, null, { duration: 1000 });
  }

  private validateBirthdate(control: FormControl): ValidationErrors {
    const value = control.value;
    if (value) {
      const m = moment(value, 'YYYY-MM-DD');
      if (m.isValid() && m.isSameOrBefore(moment(), 'day')) {
        return null;
      }
    }

    return {
      validateBirthdate: {
        valid: false
      }
    };
  }

  private createForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      birthdate: [null, [Validators.required, this.validateBirthdate]],
      cpf: ['', EceosValidators.cpf],
      gender: [Gender.NOT_INFORMED, Validators.required],
      address: this.fb.group({
        number: ['', Validators.required],
        details: '',
        zipCode: this.fb.group({
          zipCode: ['', [Validators.required, Validators.minLength(8)]],
          street: ['', Validators.required],
          district: ['', Validators.required],
          city: [null, Validators.required]
        })
      }),
      contacts: this.fb.array([this.fb.group({ contact: [new Contact()] })])
    });
  }

  private updateGeneralData(data: Patient) {
    const m = moment(data.birthdate);
    this.form.patchValue({
      name: data.name,
      birthdate: m.format('YYYY-MM-DD'),
      cpf: data.cpf,
      gender: data.gender
    });
  }

  private updateAddress(address: BrazilianAddress) {
    this.form.patchValue({
      address: {
        number: address.number,
        details: address.details,
        zipCode: {
          zipCode: address.zipCode,
          street: address.zipCode.street,
          district: address.zipCode.district,
          city: address.zipCode.city
        }
      }
    });
  }

  private updateContacts(contacts: Contact[]) {
    this.form.patchValue({
      contacts: contacts.map(c => {
        return {
          contact: c
        };
      })
    });

    this.ensureEmptyContact();
  }

  addContact() {
    this.contacts.push(this.fb.group({ contact: new Contact() }));
  }

  removeContact(index: number) {
    this.contacts.removeAt(index);
  }

  ensureEmptyContact() {
    const values = this.contacts.value as any[];
    const emptyContacts = this.contacts.value
      .map((o: any) => o.contact)
      .filter((c: Contact) => c && !c.detail) as Contact[];
    if (emptyContacts.length === 0) {
      this.addContact();
    } else if (emptyContacts.length > 1) {
      const last = emptyContacts[emptyContacts.length - 1];
      const idxToRemove = values.indexOf(values.filter(o => o.contact === last)[0]);
      this.removeContact(idxToRemove);
    }
  }

  private closeAllPanels() {
    this.acFields.forEach(f => f.closePanel());
  }

  private resetForm() {
    this.form.reset();
  }

  private populateZipCode(zc: ZipCode) {
    if (zc) {
      this.form.patchValue({
        address: {
          zipCode: {
            zipCode: zc,
            street: zc.street,
            district: zc.district,
            city: zc.city
          }
        }
      });
    }
  }

  get title() {
    return this.state === State.INSERT ? 'Novo Responsável' : 'Edição de Responsável';
  }
}

<app-layout-back [loading]="loading" [hotkeys]="hotkeys">
  <section class="middle">
    <div class="content">
      <mat-card class="headerCard card">
        <mat-card-header>
          <mat-card-title>
            <h3>Formas de Pagamento</h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="formPaymentCovenant" *ngIf="application?.hasPaymentCovenant()">
            <mat-form-field id="paymentCovenant" class="paymentCovenantName">
              <mat-label>Convênio</mat-label>
              <input matInput readonly="true" [value]="application?.paymentCovenant?.name" />
            </mat-form-field>
          </div>
          <div *ngIf="application?.hasPaymentCovenantCharged()">
            Pagamento totalmente realizado por convênio!
          </div>
          <div class="formPayments" *ngIf="!application?.hasPaymentCovenantCharged()">
            <button
              mat-raised-button
              class="paymentBtn"
              [class.paymentBtnSelected]="application?.isCashPaymentForm()"
              (click)="application?.asCashPaymentForm()"
            >
              (F3) PAGAMENTO À VISTA
            </button>
            <button
              mat-raised-button
              class="paymentBtn"
              [class.paymentBtnSelected]="application?.isDeferredPaymentForm()"
              (click)="application?.asDeferredPaymentForm()"
            >
              (F4) PAGAMENTO À PRAZO
            </button>
          </div>
          <app-payment-label-value
            label="Total"
            value="{{ application?.total }}"
            *ngIf="application?.payment"
          ></app-payment-label-value>
          <app-payment-discount
            *ngIf="application?.payment && hasPermissionDiscount"
            [application]="application"
          ></app-payment-discount>
        </mat-card-content>
      </mat-card>
      <mat-card class="paymentCard card" *ngIf="application?.payment">
        <mat-card-content>
          <app-cash-payment
            *ngIf="application?.isCashPaymentForm()"
            [cashPaymentForm]="application?.payment"
            [toPay]="application?.netValue"
          ></app-cash-payment>
          <app-deferred-payment
            *ngIf="application?.isDeferredPaymentForm()"
            [deferredPaymentForm]="application?.payment"
            [toPay]="application?.netValue"
          ></app-deferred-payment>
        </mat-card-content>
      </mat-card>
    </div>
  </section>
  <button
    mat-fab
    class="floatDefault"
    *ngIf="nextEnabled()"
    (click)="submitAndNext()"
    [disabled]="loading"
  >
    <mat-icon>done</mat-icon>
  </button>
</app-layout-back>

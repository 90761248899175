<h2 mat-dialog-title>Novo Médico</h2>
<form [formGroup]="form" (ngSubmit)="onSave()" class="data-form">
  <mat-dialog-content>
    <fieldset>
      <legend>Dados Pessoais</legend>
      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input matInput formControlName="name" autofocus focusDelay="200" />
        <mat-error *ngIf="name.invalid">O Nome é inválido</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>CRM</mat-label>
        <input matInput formControlName="crm" />
        <mat-error *ngIf="crm.invalid">O CRM é inválido</mat-error>
      </mat-form-field>
    </fieldset>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="accent" type="submit" tabindex="1" [disabled]="disabledBtn">
      Salvar
    </button>
    <button mat-button color="warn" mat-dialog-close>Fechar</button>
  </mat-dialog-actions>
</form>

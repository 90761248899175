import { Component, Input, OnInit } from '@angular/core';
import {
  ApplicationTimelineDoses,
  DoseSummary,
  ExternalApplicationTimelineDoses,
  Patient,
  PatientsRepository,
  SuggestionTimelineDoses,
  VaccinationTimeline,
  VaccinationTimelineDoses
} from '@eceos/domain';

@Component({
  selector: 'app-vaccination-timeline',
  templateUrl: './vaccination-timeline.component.html',
  styleUrls: ['./vaccination-timeline.component.scss']
})
export class VaccinationTimelineComponent implements OnInit {
  private _patient: Patient;
  data: VaccinationTimeline[] = [];
  doses: DoseSummary[] = [];

  constructor(private repository: PatientsRepository) {}

  ngOnInit() {}

  @Input()
  set patient(patient: Patient) {
    this._patient = patient;
    if (patient) {
      this.repository.listVaccinationTimeline(this._patient).subscribe(
        data => {
          this.data = data;
          this.doses = this.buildDoses(data);
        },
        e => this.clearData()
      );
    } else {
      this.clearData();
    }
  }

  private clearData() {
    this.data = [];
    this.doses = [];
  }

  buildDoses(timelines: VaccinationTimeline[]): DoseSummary[] {
    return timelines
      .map(t => t.doses)
      .reduce((arr, d) => arr.concat(d), [])
      .filter(d => d != null)
      .map(d => d.dose)
      .filter((v, i, self: DoseSummary[]) => self.indexOf(self.find(r => r.id === v.id)) === i);
  }

  get displayedColumns(): string[] {
    return ['tipo'].concat(this.doses.map(d => d.id).sort());
  }

  isSuggestion(event: VaccinationTimelineDoses) {
    return event instanceof SuggestionTimelineDoses;
  }
  isApplication(event: VaccinationTimelineDoses) {
    return event instanceof ApplicationTimelineDoses;
  }
  isExternal(event: VaccinationTimelineDoses) {
    return event instanceof ExternalApplicationTimelineDoses;
  }
}

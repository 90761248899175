<app-payment-table
  [paymentTable]="cashPaymentForm"
  [toPay]="toPay"
  (change)="clearPayback()"
></app-payment-table>
<app-payment-label-value
  *ngIf="pending > 0"
  label="Pendente"
  [value]="pending"
></app-payment-label-value>
<app-payback-table
  *ngIf="cashPaymentForm?.getTotalPaid() > toPay"
  [paybackTable]="cashPaymentForm"
  [toPay]="toPay"
></app-payback-table>

import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { HotkeySet, Hotkey } from '@eceos/common-utils';

@Component({
  selector: 'app-layout-back',
  templateUrl: './layout-back.component.html',
  styleUrls: ['./layout-back.component.scss']
})
export class LayoutBackComponent {
  @Input() backPath: string;
  @Input() loading = false;
  private _hotkeys: HotkeySet;

  constructor(private location: Location, private router: Router) {}

  @Input() set hotkeys(value: HotkeySet) {
    if (value !== this._hotkeys) {
      this._hotkeys = value
        ? value.add(
            Hotkey.key('esc')
              .description('Voltar para tela anterior')
              .do(() => this.goBack())
          )
        : value;
    }
  }

  goBack(): void {
    if (!this.backPath) {
      this.location.back();
    } else {
      this.router.navigate([this.backPath]);
    }
  }
}

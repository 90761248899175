<h2 mat-dialog-title>Nova Aplicação Externa</h2>
<form [formGroup]="form" (ngSubmit)="onSave()" class="data-form">
  <mat-dialog-content>
    <fieldset>
      <app-data-selector
        label="Tipo de Vacina"
        [repository]="vaccineTypesRepository"
        [invalid]="!vaccineType"
        (valueChange)="onTypeChange($event)"
      ></app-data-selector>
      <mat-form-field>
        <mat-label>Dose</mat-label>
        <mat-select [value]="dose" (valueChange)="onDoseChange($event)" [disabled]="!vaccineType">
          <mat-option *ngFor="let d of doses" [value]="d">
            {{ d?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Lote</mat-label>
        <input matInput [formControl]="vaccineLot">
        <mat-error *ngIf="vaccineLot.invalid">O Lote é inválido</mat-error>
      </mat-form-field>
      <mat-form-field class="cell-date">
        <mat-label>Data de Aplicação</mat-label>
        <input matInput type="date" [formControl]="date" />
        <mat-error *ngIf="date.invalid">A Data de Aplicação é inválida</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Local</mat-label>
        <input matInput [formControl]="local" />
        <mat-error *ngIf="local.invalid">O Local é inválido</mat-error>
      </mat-form-field>
    </fieldset>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-raised-button
      color="accent"
      type="submit"
      tabindex="1"
      [disabled]="!dose || !vaccineType || local.invalid || date.invalid"
    >
      Salvar
    </button>
    <button mat-button color="warn" mat-dialog-close>Fechar</button>
  </mat-dialog-actions>
</form>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonPagesModule } from '@eceos/common-pages';
import { DefaultSharedModule } from '@eceos/default-shared';
import { ContactsModule } from './contacts/contacts.module';
import { LayoutModule } from './layout/layout.module';
import { MailInputDialogModule } from './mail-input-dialog/mail-input-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    DefaultSharedModule,
    CommonPagesModule,
    LayoutModule,
    ContactsModule,
    MailInputDialogModule
  ],
  exports: [
    DefaultSharedModule,
    CommonPagesModule,
    LayoutModule,
    ContactsModule,
    MailInputDialogModule
  ]
})
export class SharedModule {}

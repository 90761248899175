import { Injectable } from '@angular/core';
import { DoseSummary } from './dose-summary';
import { ReadonlyRepositoryImpl, ApiService } from '@eceos/arch';

@Injectable({ providedIn: 'root' })
export class DosesRepository extends ReadonlyRepositoryImpl<DoseSummary, DoseSummary> {
  constructor(api: ApiService) {
    super(api.root.path('/vaccines/doses'), DoseSummary, DoseSummary);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { VaccinationReceptionDataItemComponent } from './vaccination-reception-data/vaccination-reception-data-item.component';
import { VaccinationReceptionDataComponent } from './vaccination-reception-data/vaccination-reception-data.component';
import { VaccinationReceptionPaymentModule } from './vaccination-reception-payment/vaccination-reception-payment.module';
import { VaccinationReceptionPostFinishComponent } from './vaccination-reception-post-finish/vaccination-reception-post-finish.component';
import { VaccinationReceptionComponent } from './vaccination-reception.component';
import { VaccinationReceptionRoutes } from './vaccination-reception.routes';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    VaccinationReceptionRoutes,
    VaccinationReceptionPaymentModule
  ],
  declarations: [
    VaccinationReceptionComponent,
    VaccinationReceptionDataComponent,
    VaccinationReceptionDataItemComponent,
    VaccinationReceptionPostFinishComponent
  ]
})
export class VaccinationReceptionModule {}

import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login-page',
  template: `
    <app-login [validator]="service"></app-login>
  `
})
export class LoginComponent {
  constructor(public service: LoginService) {}
}

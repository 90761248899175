import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiPath, ApiService } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LogoPathService {
  private path: ApiPath;
  constructor(api: ApiService, private sanitizer: DomSanitizer) {
    this.path = api.root.path('enterprise', 'logo');
  }

  getLogoURL(): Observable<SafeUrl> {
    return this.path.getBlob().pipe(
      map(e => URL.createObjectURL(e)),
      map(url => this.sanitizer.bypassSecurityTrustUrl(url))
    );
  }
}

<app-payment-document-value
  *ngFor="let dv of paybackTable.getChange(); let i = index"
  documentPlaceHolder="{{ getPlaceHolder(i) }}"
  [documents]="getAvailableDocuments(dv)"
  [documentValue]="dv"
  (valueChange)="onPaibackValueChange($event)"
  (completeClicked)="onCompleteClicked($event)"
>
</app-payment-document-value>
<app-payment-label-value
  *ngIf="pending > 0"
  label="Troco Pendente"
  [value]="pending"
></app-payment-label-value>

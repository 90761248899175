import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  EntityListPerspective,
  EntityTableComponent,
  SimpleEntityListPerspective
} from '@eceos/common-components';
import { Hotkey, HotkeySet } from '@eceos/common-utils';
import { NaturalPersonDocuments, PatientsRepository, PatientSummary } from '@eceos/domain';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss']
})
export class PatientsListComponent implements OnInit, OnDestroy {
  @ViewChild('list') table: EntityTableComponent<PatientSummary>;

  perspective: EntityListPerspective = new SimpleEntityListPerspective();

  cpfMask = NaturalPersonDocuments.MASK;

  private hotkeys = HotkeySet.of([
    Hotkey.key('f5')
      .description('Atualizar a listagem de pacientes')
      .do(() => this.table.invalidate()),
    Hotkey.key('f8')
      .key('ctrl+enter')
      .description('Adicionar novo paciente')
      .do(() => this.goNext())
  ]);

  constructor(
    public repository: PatientsRepository, 
    private router: Router,
  ) {}

  ngOnInit() {
    this.hotkeys.enable();
    
  }

  ngOnDestroy() {
    this.hotkeys.disable();
  }

  selectPatient(entity: PatientSummary): void {
    this.navigate(entity);
  }

  goNext(): void {
    this.navigate();
  }

  private navigate(patient?: PatientSummary) {
    if (patient) {
      this.router.navigate(['/patients', patient.id]);
    } else {
      this.router.navigate(['/patients', 'new']);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';
import { PatientVaccinationHistoryReportComponent } from './patient-vaccination-history-report/patient-vaccination-history-report.component';
import { PatientsListComponent } from './patients-list/patients-list.component';
import { PatientsRoutes } from './patients.routes';
import { PatientsDetailsComponent } from './patients-details/patients-details.component';
import { VaccinationHistoriesComponent } from './patients-details/vaccination-histories/vaccination-histories.component';
import { PatientsDetailsFormComponent } from './patients-details/patients-details-form/patients-details-form.component';
import { PaymentCovenantCardDataItemComponent } from './patients-details/payment-covenant-card-data-item/payment-covenant-card-data-item.component';
import { VaccinationTimelineComponent } from './patients-details/vaccination-timeline/vaccination-timeline.component';
import { ExternalVaccineApplicationComponent } from './patients-details/external-vaccine-application/external-vaccine-application.component';

@NgModule({
  imports: [CommonModule, SharedModule, PatientsRoutes],
  declarations: [
    PatientsListComponent,
    PatientsDetailsComponent,
    VaccinationHistoriesComponent,
    PatientsDetailsFormComponent,
    PaymentCovenantCardDataItemComponent,
    VaccinationTimelineComponent,
    ExternalVaccineApplicationComponent,
    PatientVaccinationHistoryReportComponent
  ],
  entryComponents: [PatientsDetailsFormComponent, ExternalVaccineApplicationComponent]
})
export class PatientsModule {}

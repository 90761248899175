import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  VaccineTypeSummary,
  DoseSummary,
  ExternalVaccineApplication,
  Patient,
  PatientsRepository,
  VaccineTypesRepository,
  DosesRepository
} from '@eceos/domain';
import { RequestMetadata, InterceptorConfig } from '@eceos/arch';
import * as moment from 'moment';

@Component({
  selector: 'app-external-vaccine-application',
  templateUrl: './external-vaccine-application.component.html',
  styleUrls: ['./external-vaccine-application.component.scss']
})
export class ExternalVaccineApplicationComponent implements OnInit {
  public form: FormGroup;
  public vaccineType: VaccineTypeSummary = null;
  public dose: DoseSummary = null;
  public doses: DoseSummary[] = [];
  private externalVaccineApplication: ExternalVaccineApplication = null;
  private patient: Patient = null;

  private defaultRequestMetadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ExternalVaccineApplicationComponent>,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private repository: PatientsRepository,
    public vaccineTypesRepository: VaccineTypesRepository,
    public dosesRepository: DosesRepository
  ) {
    this.dialogRef.disableClose = true;
    this.patient = data;
    this.createForm();
  }

  ngOnInit() {}

  onSave() {
    if (this.dose && this.vaccineType && this.local && this.date && this.patient) {
      this.externalVaccineApplication = new ExternalVaccineApplication();
      this.externalVaccineApplication.vaccineType = this.vaccineType;
      this.externalVaccineApplication.date = moment(this.date.value).add(12, "hours").toDate();
      this.externalVaccineApplication.dose = this.dose;
      this.externalVaccineApplication.local = this.local.value;
      this.externalVaccineApplication.vaccineLot = this.vaccineLot.value;
      this.repository
        .externalApplication(
          this.patient,
          this.externalVaccineApplication,
          this.defaultRequestMetadata
        )
        .subscribe(
          () => {
            this.showMessage('Aplicação externa efetuada com sucesso');
            this.dialogRef.close();
          },
          e => {
            this.showError(e, `Erro, ao salvar a aplicação externa`);
          }
        );
    }
  }

  get date(): AbstractControl {
    return this.form.get('date');
  }

  get local(): AbstractControl {
    return this.form.get('local');
  }

  get vaccineLot(): AbstractControl {
    return this.form.get('vaccineLot');
  }

  private createForm(): void {
    this.form = this.fb.group({
      date: ['', Validators.required],
      local: ['', Validators.required],
      vaccineLot: [null]
    });
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }

  private showError(e: Error, message: string) {
    if (e) {
      console.error(e);
    }
    this.showMessage(message);
  }

  onTypeChange(value: VaccineTypeSummary) {
    this.vaccineType = value;
    this.updateDoses();
  }

  updateDoses() {
    if (this.vaccineType) {
      this.vaccineTypesRepository.getDoses(this.vaccineType).subscribe(
        data => {
          this.doses = data;
        },
        e => {
          this.doses = [];
        },
        () => {
          this.setDose();
        }
      );
    }
  }

  private setDose() {
    if (this.dose) {
      this.dose = this.doses.find(d => d.id === this.dose.id);
    }
  }

  onDoseChange(value: DoseSummary) {
    this.dose = value;
  }
}

import { Component, OnInit } from '@angular/core';
import { CookieService } from '@eceos/common-components';

@Component({
  selector: 'app-root',
  template: `
    <ngx-loading-bar [includeSpinner]="false" height="3px"></ngx-loading-bar>
    <input type="text" style="display:none" />
    <input type="password" style="display:none" />
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit{
  
  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    this.cookieService.checkCookieConsent();
  }

}

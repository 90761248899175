import { Component, Input, OnInit } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-payment-label-value',
  templateUrl: './payment-label-value.component.html',
  styleUrls: ['./payment-label-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentLabelValueComponent implements OnInit {
  @Input() label: string;
  @Input() value: number;

  constructor() {}

  ngOnInit() {}
}

import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormControl } from '@angular/forms';
import { ValueAccessorBase } from '@eceos/arch';

@Component({
  selector: 'app-email-input',
  template: `
    <mat-form-field>
      <mat-label>E-mail</mat-label>
      <input matInput type="email" [value]="value" (keyup)="onKeyup($event)" />
      <mat-error *ngIf="invalid">O E-mail é inválido</mat-error>
    </mat-form-field>
  `,
  styleUrls: ['./email-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true
    }
  ]
})
export class EmailInputComponent extends ValueAccessorBase<string>
  implements OnInit, ControlValueAccessor {
  private constraints = [Validators.email, Validators.required];

  constructor() {
    super();
  }

  ngOnInit() {}

  protected validate(value: string): boolean {
    return this.constraints.filter(c => !c(new FormControl(value))).length > 0;
  }

  onKeyup(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    this.valueChange.next(input.value);
  }
}

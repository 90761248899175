<app-layout-back #layout backPath="/patients" [loading]="loading" [hotkeys]="hotkeys">
  <section class="content">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>Vacinação</mat-card-title>
      </mat-card-header>
      <mat-card-content class="headerCard">
        <mat-form-field>
          <mat-label>Número</mat-label>
          <input matInput value="{{ application.number }}" readonly tabindex="-1" />
        </mat-form-field>
        <mat-form-field class="cell-patient">
          <mat-label>Paciente</mat-label>
          <input matInput value="{{ application.patient.name }}" readonly tabindex="-1" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>Ações</mat-card-title>
      </mat-card-header>
      <mat-card-content class="actions">
        <button
          mat-raised-button
          class="teal-btn"
          [disabled]="loading"
          *ngIf="!hasFiscalDocument"
          (click)="emitFiscalDocument()"
        >
          Emitir Nota Fiscal (F3)
        </button>
        <button
          mat-raised-button
          class="teal-btn"
          [disabled]="loading"
          *ngIf="hasFiscalDocumentNfeOrNfce"
          (click)="printFiscalDocument()"
        >
          Imprimir Nota Fiscal (F3)
        </button>
        <button
          mat-raised-button
          class="dpurple-btn"
          [disabled]="loading"
          *ngIf="hasFiscalDocumentNfeOrNfce"
          (click)="emailFiscalDocument()"
        >
          Enviar Nota Fiscal por e-mail (F4)
        </button>

        <button
          #newSaleBtn
          mat-raised-button
          class="green-btn"
          [disabled]="loading"
          routerLink="/patients/new"
        >
          Novo Paciente (F8)
        </button>
        <button mat-button class="red-btn" [disabled]="loading" (click)="layout.goBack()">
          Voltar para a listagem (ESC)
        </button>
      </mat-card-content>
    </mat-card>
  </section>
</app-layout-back>

<div class="printBtn">
  <a mat-button target="_blank" href="/patients/{{ patient.id }}/report/vaccinationHistory">
    Imprimir
  </a>
</div>
<fieldset>
  <legend>Aplicações</legend>
  <table mat-table [dataSource]="history">
    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd/MM/yyyy HH:ss' }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Tipo de Vacina</th>
      <td mat-cell *matCellDef="let element">{{ element.type.name }}</td>
    </ng-container>
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>Produto</th>
      <td mat-cell *matCellDef="let element">{{ element.operatable.name }}</td>
    </ng-container>
    <ng-container matColumnDef="dose">
      <th mat-header-cell *matHeaderCellDef>Dose</th>
      <td mat-cell *matCellDef="let element">{{ element.dose.name }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="historyColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: historyColumns"></tr>
  </table>

  <div *ngIf="history.length == 0" class="emptyMessage">Nenhum registro encontrado</div>
</fieldset>
<fieldset>
  <legend>Outras Aplicações</legend>
  <table mat-table [dataSource]="external">
    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd/MM/yyyy' }}</td>
    </ng-container>
    <ng-container matColumnDef="local">
      <th mat-header-cell *matHeaderCellDef>Local de Aplicação</th>
      <td mat-cell *matCellDef="let element">{{ element.local }}</td>
    </ng-container>
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>Produto</th>
      <td mat-cell *matCellDef="let element">{{ element.vaccineType.name }}</td>
    </ng-container>
    <ng-container matColumnDef="dose">
      <th mat-header-cell *matHeaderCellDef>Dose</th>
      <td mat-cell *matCellDef="let element">{{ element.dose.name }}</td>
    </ng-container>
    <ng-container matColumnDef="vaccineLot">
      <th mat-header-cell *matHeaderCellDef>Lote</th>
      <td mat-cell *matCellDef="let element">{{ element?.vaccineLot || '-'  }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="externalColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: externalColumns"></tr>
  </table>

  <div *ngIf="external.length == 0" class="emptyMessage">Nenhum registro encontrado</div>
</fieldset>

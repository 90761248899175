import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ValueAccessorBase } from '@eceos/arch';

@Component({
  selector: 'app-phone-input',
  template: `
    <mat-form-field>
      <mat-label>Número</mat-label>
      <input
        matInput
        type="number"
        [value]="value"
        (keyup)="onKeyup($event)"
      />
      <mat-error *ngIf="invalid">O Número é inválido</mat-error>
    </mat-form-field>
  `,
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ]
})
export class PhoneInputComponent extends ValueAccessorBase<string>
  implements OnInit, ControlValueAccessor {
  private constraints = [
    Validators.pattern('^[1-9]{2}[1-9]{8}$'),
    Validators.minLength(8),
    Validators.required
  ];

  constructor() {
    super();
  }

  ngOnInit() {}
  protected validate(value: string): boolean {
    return this.constraints.filter(c => !c(new FormControl(value))).length > 0;
  }

  onKeyup(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    this.valueChange.next(input.value);
  }
}

<div class="deferredPayment">
  <section class="columns">
    <div class="entranceCard">
      <h3>Entrada</h3>
      <app-payment-table
        [paymentTable]="deferredPaymentForm"
        [toPay]="toPay"
        (change)="calculateParcelValue()"
      ></app-payment-table>
    </div>
    <div class="installmentCard">
      <h3>Parcelamento</h3>
      <app-payment-label-value
        label="Valor a parcelar"
        [value]="pending"
        (change)="calculateParcelValue()"
      ></app-payment-label-value>
      <section class="sub-columns">
        <mat-form-field id="installmentPolicy">
          <mat-label>Política de Parcelamento</mat-label>
          <mat-select [(ngModel)]="installmentPolicy" (change)="calculateParcelValue()">
            <mat-option *ngFor="let ip of installmentPolicies" [value]="ip">
              {{ ip.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field id="numberOfInstallments">
          <mat-label>Nº Parcelas</mat-label>
          <input
            #numberOfInstallments
            matInput
            type="number"
            [(ngModel)]="deferredPaymentForm.numberOfInstallments"
            [disabled]="!installmentPolicy"
            (keyup)="calculateParcelValue()"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>1ª Parcela</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="deferredPaymentForm.firstExpiration"
            (change)="calculateParcelValue()"
            [disabled]="!installmentPolicy"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <app-periodicities
          [(periodicity)]="deferredPaymentForm.periodicity"
          (change)="calculateParcelValue()"
          [disabled]="!installmentPolicy"
        ></app-periodicities>
        <mat-form-field id="parcelValue">
          <input matInput currencyMask [(ngModel)]="parcelValue" disabled="true" />
        </mat-form-field>
      </section>
    </div>
  </section>
</div>

<app-layout-menu>
  <section class="actions">
    <button mat-icon-button (click)="list.invalidate()">
      <mat-icon>cached</mat-icon>
    </button>
  </section>
  <section class="center">
    <mat-form-field floatLabel="never" appearance="legacy">
      <mat-label>Pesquisar</mat-label>
      <input #search matInput tabindex="-1" />
      <span matSuffix>
        <button mat-icon-button (click)="search.value = ''">
          <mat-icon>close</mat-icon>
        </button>
      </span>
    </mat-form-field>
  </section>

  <entity-cards
    #list
    [repository]="repository"
    [perspective]="perspective"
    [searchTerm]="search.value"
  >
    <mat-card *entityCard="let entity" (click)="selectPatient(entity)">
      <mat-card-header>
        <mat-card-title>{{ entity.name }}</mat-card-title>
        <mat-card-subtitle>
          {{ entity.birthdate | age }} - Sexo: {{ entity.gender.label }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="cpf">
          <mat-icon svgIcon="id-card"></mat-icon>
          <span *ngIf="entity.cpf">
            {{ entity.cpf | numberMask: cpfMask }}
          </span>
          <span *ngIf="!entity.cpf">
            Sem CPF
          </span>
        </div>
        <div class="birthdate">
          <mat-icon svgIcon="calendar-day"></mat-icon>
          <span>Nascimento: {{ entity.birthdate | date: 'shortDate' }} </span>
        </div>
      </mat-card-content>
    </mat-card>
    <ng-template tdDataTableTemplate="birthdate" let-value="value"> </ng-template>
  </entity-cards>
  <button mat-fab class="floatDefault" routerLink="/patients/new">
    <mat-icon>add</mat-icon>
  </button>
</app-layout-menu>

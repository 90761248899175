import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultSharedModule } from '@eceos/default-shared';
import { ContactsComponent } from './contacts.component';
import { CellPhoneInputComponent } from './cell-phone-input/cell-phone-input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { EmailInputComponent } from './email-input/email-input.component';

@NgModule({
  imports: [CommonModule, DefaultSharedModule],
  declarations: [
    ContactsComponent,
    CellPhoneInputComponent,
    PhoneInputComponent,
    EmailInputComponent
  ],
  exports: [ContactsComponent]
})
export class ContactsModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '../../shared/shared.module';
import { PendingVaccinesRoutes } from './pending-vaccines.route';
import { PendingVaccinesListComponent } from './pending-vaccines-list/pending-vaccines-list.component';
import { PendingVaccinesDetailsComponent } from './pending-vaccines-details/pending-vaccines-details.component';



@NgModule({
  imports: [CommonModule,
    SharedModule,
    PendingVaccinesRoutes,
    MatPaginatorModule
  ],
  declarations: [
    PendingVaccinesListComponent,
    PendingVaccinesDetailsComponent,
  ],
  entryComponents: []
})
export class PendingVaccinesModule { }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { VaccinationReceptionService } from './../vaccination-reception.service';

@Injectable({ providedIn: 'root' })
export class VaccinationReceptionPostFinishGuard implements CanActivate {
  constructor(private router: Router, private service: VaccinationReceptionService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.service.isValidFinish()) {
      this.router.navigate(['/patients']);
    }

    return true;
  }
}

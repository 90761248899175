import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PageData, SgvJson, InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { EceosValidators, Hotkey, HotkeySet } from '@eceos/common-utils';
import {
  CitiesRepository,
  Contact,
  Gender,
  Patient,
  PatientsRepository,
  PaymentCovenantCardData,
  PaymentCovenantDefaultData,
  PaymentCovenantsRepository,
  PaymentCovenantSummary,
  PermissionService,
  VaccineApplicationsActions,
  VACCINE_APPLICATIONS,
  ZipCode,
  ZipCodesRepository
} from '@eceos/domain';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ExternalVaccineApplicationComponent } from './external-vaccine-application/external-vaccine-application.component';

enum State {
  INSERT,
  UPDATE
}

@Component({
  selector: 'app-patients-details',
  templateUrl: './patients-details.component.html',
  styleUrls: ['./patients-details.component.scss']
})
export class PatientsDetailsComponent implements OnInit, OnDestroy {
  hotkeys = HotkeySet.of([
    Hotkey.key('f2')
      .description('Nova vacinação')
      .do(() => this.newReception()),
    Hotkey.key('f3')
      .description('Nova Aplicação Externa')
      .do(() => this.newExternalVaccineApplication()),
    Hotkey.key('f8')
      .key('ctrl+enter')
      .description('Salvar paciente')
      .do(() => this.onSave())
  ]);

  @ViewChild('cpfInput') cpfInput: ElementRef & HTMLInputElement;

  @ViewChildren(MatAutocompleteTrigger) acFields: MatAutocompleteTrigger[];

  private state = State.INSERT;

  private defaultRequestMetadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  form: FormGroup;
  loading = false;
  genders = Gender.values();
  externalVaccineAppliciationDialog: MatDialogRef<ExternalVaccineApplicationComponent> = null;

  zipCodes: Observable<ZipCode[]>;
  zipCodeQuery = new Subject<string>();

  patient: Patient = null;

  constructor(
    private repository: PatientsRepository,
    public zipCodesRepository: ZipCodesRepository,
    public citiesRepository: CitiesRepository,
    private router: Router,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private permissionsService: PermissionService,
    public paymentCovenantsRepository: PaymentCovenantsRepository
  ) {
    this.createForm();

    this.zipCodes = this.zipCodeQuery.asObservable().pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(q => {
        if (q.length >= 3) {
          this.loading = true;
          return this.zipCodesRepository.page(new PageData(0, 10), q);
        } else {
          return of([]);
        }
      })
    );
    this.zipCodes.subscribe(
      r => (this.loading = false),
      e => (this.loading = false)
    );
  }

  ngOnInit() {
    this.hotkeys.enable();
    this.route.paramMap
      .pipe(
        switchMap(params => {
          const from = params.get('from');
          const id = params.get('id');
          if ('new' === id) {
            this.state = State.INSERT;
            this.disableFieldsPatient();
            return new Observable(ob => ob.next(new Patient()));
          } else {
            if ('new' === from) {
              return this.repository.find(id).pipe(
                switchMap((p: Patient) => {
                  if (p) {
                    this.state = State.INSERT;
                    this.disableFieldsPatient();
                    return new Observable(ob => ob.next(new Patient(p.address, p.contacts)));
                  } else {
                    this.state = State.UPDATE;
                    return this.repository.find(id);
                  }
                })
              );
            } else {
              this.state = State.UPDATE;
              return this.repository.find(id);
            }
          }
        })
      )
      .subscribe(
        (p: Patient) => {
          this.patient = p;

          if (this.state === State.INSERT && p && p.address) {
            this.populateAddressAndContactsPatient(p);
          }

          this.populatePatient(p);
        },
        e => {
          this.patient = null;
          console.error(e);
          this.location.back();
        }
      );
  }
  ngOnDestroy() {
    this.hotkeys.disable();
  }

  fromJson(data: any): Patient {
    let zipCode = '';

    if (Boolean(data.address.zipCode.zipCode.zipCode)) {
      zipCode = data.address.zipCode.zipCode.zipCode;
    } else if (Boolean(data.address.zipCode.zipCode)) {
      zipCode = data.address.zipCode.zipCode;
    }

    return SgvJson.from.existing(data, this.patient, {
      cnsNumber: data.cns,
      birthdate: moment(data.birthdate, 'YYYY-MM-DD'),
      gender: data.gender,
      address: SgvJson.from.existing(data.address, this.patient.address, {
        zipCode: SgvJson.from.existing(data.address.zipCode, this.patient.address.zipCode, {
          zipCode: zipCode,
          city: data.address.zipCode.city
        })
      }),
      contacts: data.contacts
        .filter((c: any) => c && c.contact && c.contact.type && c.contact.detail)
        .map((c: any) => c.contact)
    });
  }

  onSave(newReception: boolean = false) {
    console.log('Salvar Paciente');
    if (!this.disabledBtn && !this.loading) {
      this.loading = true;
      this.patient = this.fromJson(this.form.value);
      const errorHandler = (err: any) => {
        this.showError(err, `Erro, ao salvar o paciente`);
        this.loading = false;
      };

      const successHandler = (p: Patient) => {
        this.resetForm();
        this.patient = p;
        this.populatePatient(p);
        this.updateLocation(p);
        this.state = State.UPDATE;
        this.loading = false;
        if (newReception) {
          this.router.navigate(['/reception/forPatient/', this.patient.id]);
        } else {
          this.showMessage('Registro salvo com sucesso');
        }
      };

      if (this.state === State.INSERT) {
        this.repository
          .insert(this.patient, this.defaultRequestMetadata)
          .subscribe(successHandler, errorHandler);
      } else {
        this.repository
          .update(this.patient, this.defaultRequestMetadata)
          .subscribe(successHandler, errorHandler);
      }
    }
  }

  onSearchPatient() {
    if (this.name.valid && this.birthdate.valid && (this.name.dirty || this.birthdate.dirty)) {
      const m = moment(this.birthdate.value, 'YYYY-MM-DD');
      if (m.isValid()) {
        this.loading = true;
        this.repository.listPatientsBy(this.name.value, m.toDate()).subscribe(
          (patients: Patient[]) => {
            if (patients && patients.find(p => p.id !== this.patient.id)) {
              this.showMessage(
                'Já existe um paciente cadastrado com esse nome e data de nascimento.'
              );
              this.clearPatient();
            } else {
              if (this.modeInsert) {
                this.clearPatient();
              }
              this.enableFieldsPatient();
              of('')
                .pipe(delay(50))
                .subscribe(() => this.cpfInput.nativeElement.focus());
            }
            this.loading = false;
          },
          e => {
            console.error(e);
            this.loading = false;
          }
        );
      }
    }
  }

  paymentCovenantSelected(value: PaymentCovenantSummary): void {
    if (value && value.providesRecord) {
      this.form.patchValue({
        paymentCovenantData: new PaymentCovenantCardData()
      });
    } else {
      this.form.patchValue({
        paymentCovenantData: new PaymentCovenantDefaultData()
      });
    }
  }

  get hasPermissionRequest(): boolean {
    return this.permissionsService.canAccess(
      VACCINE_APPLICATIONS,
      VaccineApplicationsActions.REQUEST
    );
  }
  get name(): AbstractControl {
    return this.form.get('name');
  }
  get birthdate(): AbstractControl {
    return this.form.get('birthdate');
  }
  get cpf(): AbstractControl {
    return this.form.get('cpf');
  }
  get cns(): AbstractControl {
    return this.form.get('cns');
  }
  get paymentCovenant(): AbstractControl {
    return this.form.get('paymentCovenant');
  }
  get paymentCovenantData(): AbstractControl {
    return this.form.get('paymentCovenantData');
  }
  get number(): AbstractControl {
    return this.form.get('address.number');
  }
  get details(): AbstractControl {
    return this.form.get('address.details');
  }
  get zipCode(): AbstractControl {
    return this.form.get('address.zipCode.zipCode');
  }
  get city(): AbstractControl {
    return this.form.get('address.zipCode.city');
  }
  get district(): AbstractControl {
    return this.form.get('address.zipCode.district');
  }
  get street(): AbstractControl {
    return this.form.get('address.zipCode.street');
  }
  get gender(): AbstractControl {
    return this.form.get('gender');
  }
  get contacts(): FormArray {
    return this.form.get('contacts') as FormArray;
  }
  get modeInsert(): boolean {
    return this.state === State.INSERT;
  }
  get modeUpdate(): boolean {
    return this.state === State.UPDATE;
  }
  get disabledBtn(): boolean {
    return this.form.invalid || this.form.pristine;
  }
  get age(): string {
    const m = moment(this.birthdate.value, 'YYYY-MM-DD');
    if (m.isValid()) {
      const now = moment();
      const years = now.diff(m, 'year');
      m.add(years, 'years');
      const months = now.diff(m, 'months');
      m.add(months, 'months');
      const days = now.diff(m, 'days');
      if (years === 0 && months === 0 && days === 0) {
        return '0d';
      }

      return this.ageValue(years, 'a ') + this.ageValue(months, 'm ') + this.ageValue(days, 'd');
    }

    return '';
  }

  private ageValue(value: number, label: string): string {
    return value > 0 ? value + label : '';
  }

  private enableFieldsPatient() {
    this.cpf.enable();
    this.cns.enable();
    this.gender.enable();
    this.paymentCovenant.enable();
    this.paymentCovenantData.enable();
    this.zipCode.enable();
    this.city.enable();
    this.district.enable();
    this.street.enable();
    this.number.enable();
    this.details.enable();
    this.contacts.enable();
  }

  private enableFieldsAddressAndContactsPatient() {
    this.zipCode.enable();
    this.city.enable();
    this.district.enable();
    this.street.enable();
    this.number.enable();
    this.details.enable();
    this.contacts.enable();
  }

  private disableFieldsPatient() {
    this.cpf.disable();
    this.cns.disable();
    this.gender.disable();
    this.paymentCovenant.disable();
    this.paymentCovenantData.disable();
    this.zipCode.disable();
    this.city.disable();
    this.district.disable();
    this.street.disable();
    this.number.disable();
    this.details.disable();
    this.contacts.disable();
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }

  private showError(e: Error, message: string) {
    if (e) {
      console.error(e);
    }
    this.showMessage(message);
  }

  private validatePaymentCovenantData(control: FormControl): ValidationErrors {
    const value = control.value;
    if (
      (value && value instanceof PaymentCovenantDefaultData) ||
      (value instanceof PaymentCovenantCardData && (<PaymentCovenantCardData>value).isValid())
    ) {
      return null;
    }

    return {
      validatePaymentCovenantData: {
        valid: false
      }
    };
  }

  private validateBirthdate(control: FormControl): ValidationErrors {
    const value = control.value;
    if (value) {
      const m = moment(value, 'YYYY-MM-DD');
      if (m.isValid() && m.isSameOrBefore(moment(), 'day')) {
        return null;
      }
    }

    return {
      validateBirthdate: {
        valid: false
      }
    };
  }

  private createForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      birthdate: [null, [Validators.required, this.validateBirthdate]],
      cpf: ['', EceosValidators.cpf],
      cns: [null],
      gender: [Gender.NOT_INFORMED, Validators.required],
      paymentCovenant: [null, Validators.required],
      paymentCovenantData: [
        new PaymentCovenantDefaultData(),
        [Validators.required, this.validatePaymentCovenantData]
      ],
      address: this.fb.group({
        number: ['', Validators.required],
        details: '',
        zipCode: this.fb.group({
          zipCode: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
          street: ['', Validators.required],
          district: ['', Validators.required],
          city: [null, Validators.required]
        })
      }),
      contacts: this.fb.array([this.fb.group({ contact: [] })]),
      details: ['']
    });
  }

  addContact() {
    this.contacts.push(this.fb.group({ contact: new Contact() }));
  }

  removeContact(index: number) {
    this.contacts.removeAt(index);
  }

  ensureEmptyContact() {
    const values = this.contacts.value as any[];
    const emptyContacts = this.contacts.value
      .map((o: any) => o.contact)
      .filter((c: Contact) => c && !c.detail) as Contact[];
    if (emptyContacts.length === 0) {
      this.addContact();
    } else if (emptyContacts.length > 1) {
      const last = emptyContacts[emptyContacts.length - 1];
      const idxToRemove = values.indexOf(values.filter(o => o.contact === last)[0]);
      this.removeContact(idxToRemove);
    }
  }

  private closeAllPanels() {
    this.acFields.forEach(f => f.closePanel());
  }

  private resetForm() {
    this.form.reset();
  }

  private clearPatient() {
    this.form.patchValue({
      cpf: '',
      cns: null,
      gender: Gender.NOT_INFORMED,
      paymentCovenant: null,
      paymentCovenantData: new PaymentCovenantDefaultData(),
      address: {
        number: '',
        details: '',
        zipCode: {
          zipCode: '',
          street: '',
          district: '',
          city: null
        }
      },
      contacts: [{ contact: new Contact() }],
      details: ''
    });

    this.disableFieldsPatient();
  }

  private clearZipCode() {
    this.form.patchValue({
      address: {
        zipCode: {
          zipCode: '',
          street: '',
          district: '',
          city: {
            uf: '',
            name: ''
          }
        }
      }
    });
  }

  private populatePatient(p: Patient) {
    const m = moment(p.birthdate);
    if (m.isValid()) {
      this.form.patchValue({
        name: p.name,
        birthdate: m.format('YYYY-MM-DD'),
        cpf: p.cpf,
        cns: p.cnsNumber,
        gender: p.gender,
        paymentCovenant: p.paymentCovenant,
        paymentCovenantData: p.paymentCovenantData,
        address: {
          number: p.address.number,
          details: p.address.details,
          zipCode: {
            zipCode: p.address.zipCode,
            street: p.address.zipCode.street,
            district: p.address.zipCode.district,
            city: p.address.zipCode.city
          }
        },
        details: p.details
      });

      p.contacts.forEach(c => {
        this.contacts.push(this.fb.group({ contact: [c] }));
      });

      this.ensureEmptyContact();
      this.enableFieldsPatient();
    }
  }

  private populateAddressAndContactsPatient(p: Patient) {
    this.form.patchValue({
      address: {
        number: p.address.number,
        details: p.address.details,
        zipCode: {
          zipCode: p.address.zipCode,
          street: p.address.zipCode.street,
          district: p.address.zipCode.district,
          city: p.address.zipCode.city
        }
      }
    });

    p.contacts.forEach(c => {
      this.contacts.push(this.fb.group({ contact: [c] }));
    });

    this.ensureEmptyContact();
    this.enableFieldsAddressAndContactsPatient();
  }

  private populateZipCode(zc: ZipCode) {
    if (zc) {
      this.form.patchValue({
        address: {
          zipCode: {
            zipCode: zc,
            street: zc.street,
            district: zc.district,
            city: zc.city
          }
        }
      });
    }
  }

  private updateLocation(p: Patient): void {
    this.location.replaceState(`/patients/${p && p.id ? p.id : 'new'}`);
  }

  newReception() {
    if (
      this.modeUpdate &&
      this.form.valid &&
      !this.loading &&
      this.hasPermissionRequest &&
      this.paymentCovenant.valid &&
      this.paymentCovenantData.valid
    ) {
      this.onSave(true);
    }
  }

  newExternalVaccineApplication() {
    if (
      this.modeUpdate &&
      this.form.valid &&
      !this.loading &&
      this.hasPermissionRequest &&
      this.paymentCovenant.valid &&
      this.paymentCovenantData.valid &&
      !this.disabledBtn
    ) {
      if (!this.externalVaccineAppliciationDialog) {
        this.externalVaccineAppliciationDialog = this.dialog.open(
          ExternalVaccineApplicationComponent,
          {
            data: this.patient
          }
        );
      }

      this.externalVaccineAppliciationDialog.afterClosed().subscribe((p: Patient) => {
        this.externalVaccineAppliciationDialog = null;
      });
    }
  }

  onZipcodeKeyup(event: KeyboardEvent) {
    const input = <HTMLInputElement>event.target;
    this.zipCodeQuery.next(input.value);
  }

  onZipcodeBlur(event: FocusEvent) {}

  onZipcodeFocus(event: FocusEvent) {}

  zipCodeSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.populateZipCode(value);
  }

  displayZipcodeWith(entity: ZipCode): string {
    return entity ? entity.zipCode : '';
  }
}

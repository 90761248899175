import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentDocumentValue, FinancialDocument } from '@eceos/domain';

@Component({
  selector: 'app-payment-document-value',
  templateUrl: './payment-document-value.component.html',
  styleUrls: ['./payment-document-value.component.scss']
})
export class PaymentDocumentValueComponent implements OnInit {
  @Input() documentValue: PaymentDocumentValue;

  @Input() documents: FinancialDocument[];

  @Input() documentPlaceHolder: string;

  @Output() documentChange = new EventEmitter();

  @Output() valueChange = new EventEmitter();

  @Output() completeClicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public get document() {
    if (this.documentValue) {
      const doc = this.documents.find(fd => fd.id === this.documentValue.document.id);
      return doc;
    }
    return undefined;
  }

  public set document(document: FinancialDocument) {
    if (this.documentValue) {
      this.documentValue.document = document;
      this.documentChange.emit(this.documentValue);
    }
  }

  public get value() {
    if (this.documentValue) {
      return this.documentValue.value;
    }
    return undefined;
  }

  public set value(value: number) {
    if (this.documentValue) {
      this.documentValue.value = value;
      this.valueChange.emit(this.documentValue);
    }
  }

  public onCompleteClicked(): void {
    this.completeClicked.emit(this.documentValue);
  }
}

<mat-form-field>
  <mat-label>{{ documentPlaceHolder }}</mat-label>
  <mat-select mainFocus tabindex="0" [(ngModel)]="document">
    <mat-option *ngFor="let fd of documents" [value]="fd">{{ fd.name }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field id="valueContainer">
  <input id="value" matInput currencyMask [(ngModel)]="value" />
  <span matPrefix>
    <button
      id="completBtn"
      mat-icon-button
      (click)="onCompleteClicked()"
      matTooltip="Preencher todo valor nessa forma de pagamento"
    >
      <mat-icon class="icon">keyboard_arrow_right</mat-icon>
    </button>
  </span>
</mat-form-field>

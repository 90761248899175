<app-layout-back [loading]="loading" [hotkeys]="hotkeys">
  <section class="actions">
    <button
      mat-icon-button
      matTooltip="(F3) Novo médico"
      matTooltipPosition="before"
      [disabled]="loading"
      (click)="newDoctor()"
    >
      <mat-icon>local_hospital</mat-icon>
    </button>

    <button
      mat-icon-button
      matTooltip="(F2) Novo responsável"
      matTooltipPosition="before"
      [disabled]="loading"
      (click)="newPatient()"
    >
      <mat-icon>person_add</mat-icon>
    </button>
  </section>
  <div class="content" *ngIf="vaccineApplication">
    <form [formGroup]="form" (ngSubmit)="save()" class="data-form">
      <mat-card thinBorder>
        <fieldset>
          <legend>Dados Gerais da Recepção</legend>
          <div class="generalData">
            <mat-form-field id="patient" class="patientName">
              <mat-label>Paciente</mat-label>
              <input matInput readonly="true" [value]="vaccineApplication.patient.name" />
            </mat-form-field>
            <mat-form-field class="responsibleType">
              <mat-label>Tipo de responsável</mat-label>
              <mat-select
                [value]="type"
                (valueChange)="onTypeChange($event)"
                autofocus
                focusDelay="200"
              >
                <mat-option *ngFor="let tp of types" [value]="tp">{{ tp.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <app-data-selector
              class="client"
              label="Responsável"
              *ngIf="isOtherResponsible()"
              [repository]="clientsRepository"
              [value]="responsibleByPatient.value?.client"
              (valueChange)="onClientChange($event)"
              [invalid]="responsibleByPatient.invalid"
            ></app-data-selector>
            <button
              type="button"
              mat-icon-button
              *ngIf="isOtherResponsible() && responsibleByPatient.value.client"
              (click)="editResponsiblePatientData(responsibleByPatient.value.client)"
            >
              <mat-icon>person</mat-icon>
            </button>
            <mat-form-field class="date">
              <mat-label>Data</mat-label>
              <input matInput #date type="datetime" formControlName="date" [matDatetimepicker]="datetimePicker"/>
              <mat-datetimepicker
                #datetimePicker
                type="datetime">
              </mat-datetimepicker>
              <mat-datetimepicker-toggle
                [for]="datetimePicker"
                matSuffix
              ></mat-datetimepicker-toggle>
              <mat-error *ngIf="date.invalid">A Data de Aplicação é inválida</mat-error>
            </mat-form-field>
          </div>
        </fieldset>
        <fieldset>
          <legend>Vacinas Solicitadas</legend>
          <div formArrayName="items" *ngFor="let item of items.controls; let i = index">
            <div [formGroupName]="i">
              <app-vaccination-reception-data-item
                [vaccineApplication]="vaccineApplication"
                formControlName="item"
                (valueChange)="ensureEmptyItem()"
              ></app-vaccination-reception-data-item>
            </div>
          </div>
        </fieldset>
      </mat-card>
      <button mat-fab type="submit" class="floatDefault" [disabled]="disabledBtn || loading">
        <mat-icon>done</mat-icon>
      </button>
    </form>
  </div>
</app-layout-back>

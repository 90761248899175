import { VaccinationTimelineDoses } from './vaccination-timeline-doses';
import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { VaccineTypeSummary, DoseSummary } from '../..';

export class VaccinationTimeline implements DomainEntity {
  constructor(
    public id = SgvId.gen(),
    public vaccine: VaccineTypeSummary = new VaccineTypeSummary(),
    public doses: VaccinationTimelineDoses[] = []
  ) {}

  getFromDose(dose: DoseSummary): VaccinationTimelineDoses {
    if (dose) return this.doses.find(d => d.dose.id === dose.id);
    else return null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      vaccine: SgvJson.to.optional(this.vaccine),
      doses: SgvJson.to.array(this.doses)
    });
  }

  static fromJson(data: any): VaccinationTimeline {
    return SgvJson.from.simple(data, VaccinationTimeline, {
      id: SgvJson.to.simple(data.id, String),
      vaccine: VaccineTypeSummary.fromJson(data.vaccine),
      doses: data.doses.map((d: any) => VaccinationTimelineDoses.fromJson(d))
    });
  }
}

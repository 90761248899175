import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@eceos/default-shared';
import { LayoutBackComponent } from './layout-back/layout-back.component';
import { LayoutMenuComponent } from './layout-menu/layout-menu.component';
import { MenuComponent } from './layout-menu/menu/menu.component';

@NgModule({
  imports: [CommonModule, DefaultSharedModule],
  declarations: [LayoutBackComponent, LayoutMenuComponent, MenuComponent],
  exports: [LayoutBackComponent, LayoutMenuComponent, MenuComponent]
})
export class LayoutModule {}

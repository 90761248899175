<mat-nav-list>
  <a mat-list-item routerLink="/patients/">
    <mat-icon mat-list-icon>list</mat-icon>
    <h4 matLine>Pacientes</h4>
  </a>
  <a mat-list-item routerLink="/pendingVaccines/">
    <mat-icon mat-list-icon>error</mat-icon>
    <h4 matLine>Vacinas Pendentes</h4>
  </a>
  <a mat-list-item routerLink="/settings/">
    <mat-icon mat-list-icon>settings</mat-icon>
    <h4 matLine>Configurações</h4>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item (click)="logout()">
    <mat-icon mat-list-icon>exit_to_app</mat-icon>
    <h4 matLine>Logout</h4>
  </a>
</mat-nav-list>

import { Injectable } from '@angular/core';

import { PaymentCovenantSummary } from './payment-covenant';
import { map } from 'rxjs/operators';
import { ReadonlyRepositoryImpl, ApiService } from '@eceos/arch';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentCovenantsRepository extends ReadonlyRepositoryImpl<
  PaymentCovenantSummary,
  PaymentCovenantSummary
> {
  constructor(api: ApiService) {
    super(api.root.path('paymentCovenants'), PaymentCovenantSummary, PaymentCovenantSummary);
  }

  getPaymentCovenantForPatient(patientId: string): Observable<PaymentCovenantSummary> {
    return this.api
      .path('/one/forPatient/' + patientId)
      .getJson()
      .pipe(map(r => PaymentCovenantSummary.fromJson(r)));
  }

  calculateValue(id: string, operatableId: string): Observable<number> {
    return this.api
      .path('/' + id + '/valueOf/' + operatableId)
      .getJson<any>()
      .pipe(map(r => r.value));
  }
}

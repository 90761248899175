<mat-form-field>
  <mat-label>Nº Carteirinha</mat-label>
  <input
    #cardNumber
    matInput
    [value]="value.cardNumber"
    (keyup)="onCardNumberChange(cardNumber.value)"
  />
  <mat-hint *ngIf="invalid" class="mat-error">O Nº da Carteirinha é inválido</mat-hint>
</mat-form-field>

import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[focused]'
})
export class FocusedDirective implements OnInit {
  private value = false;

  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    this.updateState();
  }
  @Input()
  set focused(value: boolean) {
    this.value = value;
    this.updateState();
  }

  private updateState() {
    if (this.value) {
      this.elementRef.nativeElement.focus();
      this.childs.forEach(e => {
        if (e.focus) {
          e.focus();
        }
      });
    }
  }

  private get childs(): any[] {
    const v = this.elementRef.nativeElement.querySelector('[mainFocus]');
    return Array.isArray(v) ? v : [v];
  }
}

import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Patient, PatientsRepository, VaccinationHistory, LogoPathService } from '@eceos/domain';
import { throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-patient-vaccination-history-report',
  templateUrl: './patient-vaccination-history-report.component.html',
  styleUrls: ['./patient-vaccination-history-report.component.scss']
})
export class PatientVaccinationHistoryReportComponent implements OnInit {
  patient: Patient = null;

  vaccinations: VaccinationHistory[] = [];

  error: Error = null;

  date = new Date();

  logoUrl: SafeUrl = null;

  constructor(
    private repository: PatientsRepository,
    private route: ActivatedRoute,
    private logoPathService: LogoPathService
  ) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap(p =>
          p.get('id')
            ? this.repository.find(p.get('id'))
            : throwError(new Error('Paciente não encontrado'))
        )
      )
      .subscribe(
        patient => {
          this.patient = patient;
          this.loadHistory();
        },
        e => (this.error = e)
      );
    this.logoPathService.getLogoURL().subscribe(url => (this.logoUrl = url));
  }

  loadHistory() {
    console.log('carregando histórico');
    if (this.patient) {
      this.repository
        .listVaccinationHistoriesFor(this.patient)
        .subscribe(h => (this.vaccinations = h));
    } else {
      this.vaccinations = [];
    }
  }
}
